export const transformIndustryData = (rawData) => {
  // Map through the raw data and transform it into the sunburst format
  const sunburstData = {
    name: "Third Party Risk Management",
    children: rawData?.map(industry => {
      return {
        name: industry.industry || "Industry",
        children: industry.subIndustries.map(subIndustry => {
          return {
            name: subIndustry.subIndustry,
            children: subIndustry.vendors.map(vendor => {
              return {
                name: vendor?.name,
                domain: vendor?.domain,
                color: "#60A0D1"
                // value: 10,
              };
            })
          };
        })
      };
    })
  };

  return sunburstData;
};


export const transformInsideData = async (data) => {
  const transformData = (category, categoryData) => {
    const children = Object.keys(categoryData).map((key) => ({
      name: key,
      value: 1
    }));

    if (children.length && children.length > 0) {
      return {
        name: category,
        children: children,
        color: "#2A5BC5"
      };
    }
    else {
      return {
        name: category,
        value: 1
      };
    }
  };
  const value = data["Inside Out"];
  const newData = {
    name: "Inside Out " + (Math.trunc(value) || 0) + "/100",
    // value:1,
    children: [],
    color: "#6C32C2"
  };

  // Iterate over all the categories and transform them into the desired format
  for (const category in data) {
    if (data.hasOwnProperty(category) && category !== 'Inside Out') {
      const categoryData = data[category];
      const transformedCategory = transformData(category, categoryData);
      if (transformedCategory?.children?.length > 0) {
        newData.children.push(transformedCategory); // Add transformed categories to children
      }
    }
  }
  return newData;
};



export const transformOutsideData = async (data) => {
  const transformArrayData = (category, categoryData) => {

    const children = categoryData
      .filter(item => item !== null)
      .map((item, index) => ({
        name: item,
        value: 1
      }));
    if (children && children.length && children.length > 0) {
      return {
        name: category, // Category name (e.g., "top5CVEs")
        children: children,
        color: "#186A5F"
      };
    }
    else {
      return {
        name: category, // Category name (e.g., "top5CVEs")
        value: 1
      };
    }
  };

  if (data && data.length > 0) {
    const value = (data[0].hasOwnProperty('Outside In')) ? data[0]?.['Outside In'][0] : null;
    var topLevel = {
      name: "Outside In " + (Math.trunc(value) || 0) + "/100",
      children: [],
      color: "#C42FC6"
    };
  }


  for (const category in data[0]) {
    if (data[0].hasOwnProperty(category) && category !== 'Outside In') {
      const categoryData = data[0][category];
      const transformedCategory = transformArrayData(category, categoryData);
      if (transformedCategory?.children?.length > 0) {
        topLevel.children.push(transformedCategory);
      }
    }
  }
  return topLevel;
};

// Sample data
export const sampleOutsideData = [
  {
    top5CVEs: [
      "CVE-2021-3618",
      "CVE-2021-23017",
      "CVE-2023-44487",
      "CVE-2015-2331",
      "CVE-2015-6833"
    ],
    dns: [
      " Invalid SSL/TLS Subject ",
      " Invalid SSL/TLS ",
      " Invalid Issue",
      " SSL/TLS Subject "
    ],
    misconfiguration: [
      "content-security-policy",
      "content-security-policy-misconfigured",
      "x-frame-options",
      "x-content-type-options",
      "x-permitted-cross-domain-policies"
    ],
    encryption: [
      null
    ],
    breaches: [
      "Employee Data Breach",
      "Employee Data Breach",
      "Employee Data Breach",
      "Employee Data Breach",
      "Employee Data Breach",
      "Employee Data Breach",
      "Employee Data Breach",
      "Employee Data Breach"
    ],
    ["Outside In"]: [82]
  }
];

// SampleInsideData
export const SampleInsideData = {
  businessRisk: {
    "Financial Risk": "2.86",
    "Operational Risk": "8.57",
    "Compliance Risk": "5.71"
  },
  failedControls: {
    "Information Security Policy": "16.67",
    "Service Level Agreement": "33.33",
    "SOC 2": "16.67",
    "ISO-27001": "16.67",
    "Introperability & Portability": "16.67"
  },
  questionnaire: {
    "Essential": "83.33",
    "CAIQ Questionnaire": "16.67"
  },
  impactOnVendor: {
    "Fines": "88.30",
    "Legal penalties": "90.43",
    "Regulatory scrutiny": "82.98",
    "Data breaches": "78.72",
    "Loss of trust": "88.30",
    "": "7.45"
  },
  impactOnClient: {
    "Legals actions": "92.55",
    "Financial penalties": "82.98",
    "Loss of trust": "82.98",
    "Legal liabilities": "80.85"
  },
  ["Outside In"]: 14.4
};












