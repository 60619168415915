import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Modal,
  Typography,
  InputLabel,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EyeMenu from "../../assets/eye-menu.svg";
import Trash from "../../assets/trahs.svg";
import CriticalImg from "../../assets/critical-image.svg";
import LowImage from '../../assets/low-img.svg';
import MediumImage from '../../assets/medium-img.svg';
import HighImage from '../../assets/high-img.svg';
import CrossImg from "../../assets/cross-select.svg";
import { CustomNoRowsOverlay } from "../Assessment.js";
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import moment from "moment/moment";
import GetRequest from "../../components/apiConnections/getRequest.js";
import { useSnackbar } from "notistack";
import { Link, useNavigate, useParams } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest.js";
import UploadIcon from '../../assets/upload-iconfile.svg';
import RequestIcon from '../../assets/request-uploadicon.svg';
import VendorRequestDocuments from "../VendorRequestDocuments.js";
import VendorUploadDocuments from "../VendorUploadDocuments.js";

function VendorDocuments() {
  const data = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const [contactPersonList, setContactPersonList] = useState([]);
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [vendorDocumentsTypes, setVendorDocumentsTypes] = useState([]);
  const [vendorAvailableDocuments, setVendorAvailableDocuments] = useState([]); // State to store documents list fetched from the API
  const [vendorNotAvailableDocuments, setVendorNotAvailableDocuments] = useState([]);
  const [openRequestModel, setOpenRequestModel] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const handleCloseModel = () => { setOpenUploadModel(false); setOpenRequestModel(false) }
  const [showDocumentEvidence, setShowDocumentEvidence] = useState("Documents");

  const [docuementColumns, setDocumentColumns] = useState([
    { field: "uid", headerName: "#", editable: false,  flex: 2, maxWidth: 80},
    {
      field: "documentType",
      headerName: "Document Type",
      editable: false,
      width: 220,
      headerAlign: "left",
      align: "left",
      flex: 2,
      renderCell: (params) => params.row.documentType?.name || "N/A", // Safely access the name property
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      editable: false,
      width: 50,
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => formatDate(params.value)
    },
    {
      field: "action",
      headerName: "Action",
      width: 50,
      sortable: false,
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <RowMenu
            handleView={() => {
              const fileUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${params.row.documentName}`;
              window.open(fileUrl, '_blank', 'noopener,noreferrer');
            }} />
        );
      },
    }
  ]);
  const [vendorQuestionnaireEvidenceData, setVendorQuestionnaireEvidenceData] = useState([]);
  const vendorQuestionnaireEvidenceColumn = [
    // { field: 'assessmentId', headerName: 'Assessment ID', width: 150 },
    { field: 'set', headerName: 'Set', flex:1, },
    { field: 'category', headerName: 'Category',  flex:1, },
   
    {
      field: 'que', headerName: 'Question',  flex:1,
    },
    // { field: 'ans', headerName: 'Answer', flex:1, headerAlign: "center", align: "center" },
    { field: 'remark', headerName: 'Remark', flex:1,},
    {
      field: 'fileName',
      headerName: 'Action',
      flex:1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <RowMenu
          handleView={() => {
            // const fileUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/${process.env.REACT_APP_OPEN_VENDOR_DOCUMENT}/${params.row.documentName}`;
            const fileUrl =`${process.env.REACT_APP_BACKEND_BASE_URL}/${"evidence"}/${params.row.fileName}`; 
            window.open(fileUrl, '_blank', 'noopener,noreferrer');
          }} />
          // <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
          //   <Link
          //     to={`${process.env.REACT_APP_BACKEND_BASE_URL}/${"evidence"}/${params.row.fileName}`}
          //     target="_blank"
          //     rel="noopener noreferrer"
          //   >
          //     <Button>
          //       <VisibilityIcon />
          //     </Button>
          //   </Link>
          // </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getVendorDocumentsTypes();
    getVendorContactPerson();
    getVendorDocuments();
    getVendorQuestionnaireEvidenceList();
  }, []);

  useEffect(() => {
    if (vendorDocumentsTypes.length > 0) {
      let notAvailableDocuments = [];

      if (vendorAvailableDocuments.length == 0) {
        notAvailableDocuments = [...vendorDocumentsTypes];
      } else {
        const availableDocumentIds = vendorAvailableDocuments && vendorAvailableDocuments.length > 0 && vendorAvailableDocuments.map(
          (doc) => doc?.documentType?.type
        );
        notAvailableDocuments = vendorDocumentsTypes.filter(
          (doc) => !availableDocumentIds.includes(doc?.type)
        );
      }

      setVendorNotAvailableDocuments(notAvailableDocuments);

      //   const documentsWithIndex = vendorAvailableDocuments?.map((document, index) => ({
      //     ...document,
      //     index: index + 1 // Adding 1 to make the index start from 1
      // }));

      // setVendorAvailableDocuments(documentsWithIndex);
    }
  }, [vendorDocumentsTypes, vendorAvailableDocuments]); // Re-run the effect when these dependencies change

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const getVendorContactPerson = async () => {
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setContactPersonList(obj2);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Failed Questions not find", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Failed Questions not find", { variant: "error" });
      return false;
    }
  };

  function RowMenu({ handleView }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick} className='three-dots-btn'>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className='rowmenu'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        >
          <MenuItem onClick={handleView} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
          {/* <MenuItem onClick={handleRemove}> <img src={Trash} /> Remove</MenuItem> */}
        </Menu>
      </>
    );
  }
  const filteredRows =
    vendorAvailableDocuments
      .map((val, i) => ({
        ...val,
        uid: i + 1,
      }));

  const getVendorDocumentsTypes = async () => {
    setShowLoader(true);
    try {

      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_DOCUMENT_TYPES}`)
          .then((response) => {
            setVendorDocumentsTypes(response?.data?.data);
            setShowLoader(false);
          })
          .catch((errApi) => {
            enqueueSnackbar("Requested Url not found", { variant: "error" });
            setShowLoader(false);
          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      setShowLoader(false);
      return false;
    }
  };

  const getVendorDocuments = async () => {
    setShowLoader(true);

    try {
      const vendorPayload = {
        vendorDomain: vendorUrl
      };

      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOCUMENT_BY_VENDOR_DOMAIN}`,
          vendorPayload)
          .then((documentDetails) => {
            setVendorAvailableDocuments(documentDetails?.data?.data);
            setShowLoader(false);
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" });
            setShowLoader(false);
          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      setShowLoader(false);
      // setError(true);
      return false;
    }
  };

  const transformVendorEvidenceData = (apiData) => {
    if (!apiData) return [];
    return apiData.flatMap((item) => {
      return Object.entries(item.questionnaires).flatMap(([category, questions]) =>
        questions.map((question) => ({
          id: `${item.assessmentId}-${question.que.text}`, // Create a unique ID for each row
          assessmentId: item.assessmentId,
          set: item.set,
          category,
          que: question.que.text,
          question: question.que,
          ans: question.ans,
          remark: question.remark,
          fileName: question.fileName,
        }))
      );
    });
  };

  const getVendorQuestionnaireEvidenceList = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_QUESTIONNAIRE_EVIDENCE_LIST}?vendorDomain=${vendorUrl}`,
        )
          .then((response) => {
            const transformedData = transformVendorEvidenceData(response?.data.data); // Transform the data
            setVendorQuestionnaireEvidenceData(transformedData); // Set the transformed data
            setShowLoader(false);
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" });
            setShowLoader(false);
          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      setShowLoader(false);
      // setError(true);
      return false;
    }
  };

  const handleDocumentorEvidenceChange = (event) => {
    setShowDocumentEvidence(event.target.value);
  };

  const handleRequestModelOpen = () => {
    // if (vendorNotAvailableDocuments.length === 0) {
    //   enqueueSnackbar("All Documents already Uploaded", { variant: "info" });
    // }
    // else {
      setOpenRequestModel(true);
    // }
  }

  const handleUploadModelOpen = () => {
    if (vendorNotAvailableDocuments.length === 0) {
      enqueueSnackbar("All Documents already Uploaded", { variant: "info" });
    }
    else {
      setOpenUploadModel(true);
    }
  }

  return (
    <>
      {/* <Loader show={loading} /> */}
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
        {showDocumentEvidence === "Documents" ?
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
                value={paginationModel.pageSize}
                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>{" "}
          </div>
          : 
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "50px"
            }}
          >
            {/* <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
                value={paginationModel.pageSize}
                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl> */}
            {/* <span className="entries-per">entries per page</span>{" "} */}
          </div>
        }
          <div className='document-buttons'>
            {showDocumentEvidence === "Documents" ?
              <>
                <Button
                  sx={{ marginRight: "0px" }}
                  variant="contained"
                  className="add-questionnaire-button"
                  // startIcon={<Iconify icon="material-symbols:upload" />}
                  onClick={handleRequestModelOpen}
                >
                  <img src={RequestIcon} alt='icon' style={{ marginRight: "13px" }} />
                  Request Document
                </Button>
                <Button
                  variant="contained"
                  className="add-questionnaire-button"
                  // startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={handleUploadModelOpen}
                >
                  <img src={UploadIcon} alt='icon' style={{ marginRight: "13px" }} />
                  Upload Document
                </Button>
              </>
              : null
            }
            <FormControl sx={{ width: '200px' }}>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={showDocumentEvidence}
                onChange={handleDocumentorEvidenceChange}
                sx={{ height: '40px', padding: '4px' }} // Adjusted height and padding for Select

              >
                <MenuItem key="Documents" value="Documents">
                  Documents
                </MenuItem>
                <MenuItem key="Evidences" value="Evidences">
                  Evidences
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="issues-tab-table">
            {showDocumentEvidence === "Documents" ? (
              <Box
                sx={{
                  width: "100%",
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%",
                    overflowX: "hidden",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%",
                    overflowX: "auto",
                  },
                }}
              >

                <DataGrid
                  loading={showLoader}
                  rows={filteredRows}
                  columns={docuementColumns}
                  getRowId={(row) => row._id}
                  rowHeight={70}
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  components={{
                    NoRowsOverlay: () => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        No data
                      </Box>
                    ),
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [],
                      },
                    },
                  }}
                  autosizeOptions={{
                    columns: ["id", "firstName", "lastName"],
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  paginationMode="client"
                  disableSelectionOnClick
                  autoHeight
                  className="datagrid-table"
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      minWidth: "100%", // Column headers take up full width
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      minWidth: "100%", // Rows take up full width
                    },
                  }}
                />
              </Box>
            ) : (
              <div>
                <Box
                  sx={{
                    width: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                      minWidth: "100%",
                      overflowX: "hidden",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      minWidth: "100%",
                      overflowX: "auto",
                    },
                  }}
                >
                  <DataGrid
                    sx={{ border: "none", width: "100%", height: "400px" }}
                    rows={vendorQuestionnaireEvidenceData}
                    getRowId={(row) => row.id} // Use the custom ID for each row
                    columns={vendorQuestionnaireEvidenceColumn}
                    components={{
                      NoRowsOverlay: () => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "18px",
                          }}
                        >
                          No data
                        </Box>
                      ),
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterValues: [],
                        },
                      },
                    }}
                    autosizeOptions={{
                      columns: ["id", "firstName", "lastName"],
                      includeOutliers: true,
                      includeHeaders: false,
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    slots={{
                      toolbar: GridToolbar,
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: false,
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                      },
                    }}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    disableSelectionOnClick
                    autoHeight
                    className="datagrid-table"
                  />
                </Box>
              </div>
            )}
          </div>
        </div>
      </Card>
      <Modal
        open={openRequestModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <VendorRequestDocuments
          handleModelCloseFunction={() => handleCloseModel()}
          vendorNotAvailableDocuments={vendorDocumentsTypes}
          vendorUrl={vendorUrl}
          getVendorDocuments={() => getVendorDocuments()}
          vendorDocumentsTypes={() => vendorDocumentsTypes}
          contactDetails={contactPersonList}
        />
      </Modal>
      <Modal
        open={openUploadModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <VendorUploadDocuments
          vendorNotAvailableDocuments={vendorNotAvailableDocuments}
          vendorUrl={vendorUrl}
          handleModelCloseFunction={() => handleCloseModel()}
          getVendorDocuments={() => getVendorDocuments()}
        />
      </Modal>
    </>
  );
}

export default VendorDocuments;
