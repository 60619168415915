import { Box, Grid, styled, FormControl, Select, Typography, IconButton, MenuItem, Menu, Card } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Delete from "../assets/delete.svg";
import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";
import SendAssessmentModal from "../components/modal/sendAssessment";
import ShowAssessmentModal from "../components/modal/showAssessment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./css/assessment.css";
import ModalDialog from "./ModalDialog";
import ExtendDeadlineModel from "./models/ExtendDeadlineModel";
import NewHeader from "../layouts/dashboard/header/NewHeader";
import Trash from '../assets/trahs.svg';
import SendArrow from '../assets/top-arrow-btn.svg';
import ExtendDeadline from '../assets/extend-deadline.svg';
import Markcomplete from '../assets/mark-complete.svg';
import { useSelector } from "react-redux";

export const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

export function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data Found</Box>
    </StyledGridOverlay>
  );
}
export default function Assessment() {
  const { enqueueSnackbar } = useSnackbar();
  let selector = useSelector((state) => state.permissionList.permissionList);
  const [assessments, setAssessments] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteAssessmentModal, setShowDeleteAssessmentModal] = useState(false);
  const [showAssessmentDetailModal, setShowAssessmentDetailModal] = useState(false);
  const [showExtendsDeadlineModal, setShowExtendsDeadlineModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [activeAssessment, setActiveAssessment] = useState({});
  const [sendAssessmentModal, setSendAssessmentModal] = useState(false);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const [deadline, setDeadline] = useState('');
  const [extendDeadline, setExtendDeadline] = useState('');

  const [updateAssessmentStatus, setUpdateAssessmentStatus] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

   let subscriptionDetails = useSelector((state) => state.subscriptionDetails.subscriptionDetails) || []; 
   const handleModelOpen = () => {
      const details = subscriptionDetails?.find(sub => sub.id === 'assessments' ); 
      if(details?.total_added >=  details?.subscribtion_value){
         enqueueSnackbar("Sorry, you have complete the limit for this in subscription.", { variant: "error" });
         return; 
      }
      setSendAssessmentModal(true);
   }
  const [columns, setColumns] = useState([
    { field: "uid", headerName: "#", editable: false, maxWidth: 60, disableColumnMenu: true, },
    {
      field: "url",
      headerName: "Vendor Name",
      editable: false,
      flex: 1.1,
      minWidth: 170,
      headerAlign: 'left',
      align: 'left',
      disableColumnMenu: true,
      renderCell: (params) => {
        const colors = ['#7bba2f', '#b11382', '#baaf2f', '#362bff', '#23e2ff', '#b11382', '#e0e0e1'];
        const firstLetter = params.value ? params.value.charAt(0).toUpperCase() : '';
        const rowIndex = params.row.uid
        const colorIndex = rowIndex % colors.length;
        const backgroundColor = colors[colorIndex];
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div className="v-vendor-radius">
              <span className="logo-image-vendor circle-vendore-name"
                style={{
                  backgroundColor,
                }}>
                {firstLetter}
              </span>
            </div>
            <span>{params.value}</span>
          </div>

        );
      },
    },
    { field: "email", headerName: "Email Id", editable: false, minWidth: 230, headerAlign: 'left', align: 'left', flex: 1, disableColumnMenu: true, },
    { field: "set", headerName: "Set", editable: false, minWidth: 50, headerAlign: 'left', align: 'left', flex: 1, disableColumnMenu: true, },
    ,
    {
      field: "lastDate",
      headerName: "Deadline",
      editable: false,
      sortable: true,
      minWidth: 130,
      headerAlign: 'center', align: 'center', flex: 0.6,
      disableColumnMenu: true,
      sortComparator: (v1, v2, param1, param2) => {
        let firstDate = moment(v1, "DD/MM/YYYY").valueOf();
        let secondDate = moment(v2, "DD/MM/YYYY").valueOf();
        return firstDate - secondDate;
      },
    },
    {
      field: 'assessmentStatus',
      headerName: 'Status',
      editable: false,
      minWidth: 130,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        let status = params.value;
        return (status !== "Extended Deadline" ?
          <span className={`statusClass ${status}`}>{status}</span>
          :
          <span className={`statusClass Extended_Deadline`}>{status}</span>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      minWidth: 40,
      sortable: false,
      headerAlign: 'center', align: 'center', flex: 0.2,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) => handleActionClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  console.log("selectedRow",selectedRow);

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleActionClick = (event, row) => {
    event.stopPropagation();
    setDeadline(moment.unix(row.deadline).format('YYYY-MM-DD'));
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = (event) => {
    event.stopPropagation();
    setSelectedRow(null);
    setDeadline(null);
    setExtendDeadline(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    getAssessment();
  }, []);

  useEffect(() => {
    getAssessment(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }

  const getAssessment = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ASSESSMENT}`,
          {}
        )
          .then((resChart) => {
            let data = resChart.data.data;
            setisNotFound(!resChart.data.data);
            setAssessments(data);
            setRows(
              data.length
                ? [
                  ...data?.map((d, i) => ({
                    ...d,
                    uid: i + 1,
                    id: d._id,
                    url: d.url,
                    lastDate: moment.unix(d.deadline).format("DD/MM/YYYY"),
                  })),
                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errChart) => {
            // enqueueSnackbar(errChart.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const deleteAssessmentAPI = async () => {
    const payload = {
      assessmentId: activeAssessment._id,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_ASSESSMENT}`,
          payload,
          {}
        )
          .then((resChart) => {
            setShowLoader(false);
            setShowDeleteAssessmentModal(false);
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            getAssessment();
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const ModalContentAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to remove this assessment ?
        </h2>
      </Typography>
    );
  };

  const ModalContentCompleteAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Markcomplete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to mark this assessment as complete ?
        </h2>
      </Typography>
    );
  };

  const extendsDeadlineDateAPI = async (e) => {
    if (!extendDeadline) {
      enqueueSnackbar('Please select the extend deadline date', { variant: 'error' })
      return false;
    }

    if (extendDeadline <= deadline) {
      enqueueSnackbar('Extend deadline date should be greater then the deadline date', { variant: 'error' })
      return false;
    }
    // Convert the date strings to Unix timestamps
    const deadlineUnix = moment(deadline, "YYYY-MM-DD").unix();
    const extendDeadlineUnix = moment(extendDeadline, "YYYY-MM-DD").unix();
    const payload = {
      assessment_id: activeAssessment._id,
      deadline: extendDeadlineUnix,
    };
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_ASSESSMENT_DEADLINE}`,
          // `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"update-assessment-deadline"}`,
          payload,
          {}
        )
          .then((res) => {
            // let data = resChart.data.data;
            setShowLoader(false);
            setShowExtendsDeadlineModal(false)
            enqueueSnackbar(res.data?.message, { variant: "success" });
            getAssessment();
          })
          .catch((err) => {
            enqueueSnackbar(err.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  }

  const updateAssessment = async (status) => {
    const payload = {
      assessment_id: activeAssessment._id,
      assessmentStatus: updateAssessmentStatus
    };
    if (status) {
      payload.assessmentStatus = status;
    }
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_ASSESSMENT_STATUS}`,
          payload,
          {}
        )
          .then((res) => {
            // let data = resChart.data.data;

            setShowLoader(false);
            enqueueSnackbar(res.data?.message, { variant: "success" });
            // setShowAcceptDeclineModal(false)
            getAssessment()
          })
          .catch((err) => {
            enqueueSnackbar(err?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
    // setShowAcceptDeclineModal(false)
    setShowCompleteModal(false)
  }

  const handleExtendDeadlineChange = (event) => {
    setExtendDeadline(event.target.value);
  };
  const handleAssesmentStatusChange = (event) => {
    setUpdateAssessmentStatus(event.target.value);
  };
  const getCurrentDate = (date) => {
    let parseDate = date
    if (date !== deadline) parseDate = moment(parseDate).add(1, 'day')
    let currentDate = new Date(parseDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleRowClick = (params) => {
    const id = params?.id;
    const email = params?.email;
    if (checkPermission("View Assessment Detail")) {
      navigate(`/dashboard/vendor-answer/${id}`, { state: { data: params, email, prevUrl: '/dashboard/assessment', locationFrom: 'assessmentTable' } })
    }
  };
  return (
    <>
      <Helmet>
        <title> Assessments | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Assessment" />
        <Grid container spacing={3} sx={{ mb: 0 }} className="ipad-space">
          <Grid item xs={12} md={12}>
            <Card className="full-coverdesign margin-questionnaire">
              <div className="cover-table">
                {/* <QuestionnaireTable/> */}
                {/*New Table */}
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    // position: "relative",
                    // top: "6px",
                    // left: "8px",
                    // marginBottom:"10px"
                  }}
                >
                  {checkPermission("View Assessment") &&
                    <div className="assessment-top-table">
                      <FormControl className='select-entries' variant="outlined" size="small">
                        <Select
                          value={paginationModel.pageSize}
                          onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                      </FormControl>

                      <span className='entries-per'>entries per page</span>
                    </div>
                  }

                  {checkPermission("Send Assessment") ?
                    <Button
                      variant="contained"
                      className="send-assessment"
                      onClick={() => handleModelOpen(true)}
                    >
                      <img src={SendArrow} className="send-arrow-icon" /> Send Assessment
                    </Button>
                    : null}
                </div>
                {checkPermission("View Assessment") &&
                  <Box
                    sx={{
                      width: '100%',
                      '& .MuiDataGrid-columnHeaders': {
                        minWidth: '100%',
                        overflowX: 'hidden',
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        minWidth: '100%',
                        overflowX: 'auto',
                      },
                    }}
                  >
                    <DataGrid
                      loading={showLoader}
                      // {(checkPermission("Edit Assessment") &&   
                      rows={checkPermission("View Assessment") && rows} // Ensure you're using the filtered rows
                      columns={columns}
                      pageSize={pageSize}
                      rowHeight={70}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      pageSizeOptions={[5, 10, 25, 50]}
                      onPaginationModelChange={handlePaginationModelChange}
                      paginationModel={paginationModel}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                      }}
                      paginationMode="client"
                      autoHeight
                      className='datagrid-table click-hover-datagrid'
                      sx={{
                        '& .MuiDataGrid-columnHeaders': {
                          minWidth: '100%', // Column headers take up full width
                        },
                        '& .MuiDataGrid-virtualScroller': {
                          minWidth: '100%', // Rows take up full width
                        },
                      }}
                      onRowClick={(params) => handleRowClick(params.row)}
                    />
                  </Box>
                }
              </div>
            </Card>
          </Grid>
        </Grid>

        {selectedRow && (
          <Menu
            id="actions-menu"
            className='rowmenu assessment-send'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {(checkPermission("Edit Assessment") &&
              (selectedRow.assessmentStatus === "Submitted" || selectedRow.assessmentStatus === "Engagement")) ?
              <MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowCompleteModal(true);
                }}>
                <img src={Markcomplete} /> Mark as complete
              </MenuItem> : null}

            {(checkPermission("Edit Assessment") &&
              (selectedRow.assessmentStatus !== "Completed" ) && selectedRow.extendedDeadlineCount.length < 2) ?
              <MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowExtendsDeadlineModal(true);
                }}
              >
                <img src={ExtendDeadline} />
                Extend Deadline
              </MenuItem> : null}
            {/* {(selectedRow.assessmentStatus === 'Completed' || selectedRow.assessmentStatus === 'Engagement') && (
              <MenuItem
               className="image"
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  // setShowAcceptDeclineModal(true);
                }}
              >
                <AccessTimeIcon 
                className="image"
                />
                Accept/Decline
              </MenuItem>
            )} */}
            {checkPermission("Delete Assessment") ?
              <MenuItem
                onClick={() => {
                  setActiveAssessment(selectedRow);
                  setShowDeleteAssessmentModal(true);
                }}
              >
                <img src={Trash} />
                Remove
              </MenuItem>
              : null}
            {/* <MenuItem
              onClick={() => {
                navigate(`/dashboard/security-control/${selectedRow._id}`);
              }}
            >
              <SecurityIcon />
              Security
            </MenuItem> */}
            {/* <MenuItem
              onClick={(event) => {
                handleAnswerClick(selectedRow._id, selectedRow.email);
              }}
            >
              <VisibilityIcon />
              Visibility
            </MenuItem> */}
          </Menu>
        )}

      </Container>

      <ShowAssessmentModal
        handleClose={() => setShowAssessmentDetailModal(false)}
        open={showAssessmentDetailModal}
        data={activeAssessment}
      />
      <ModalDialog
        handleClose={() => setShowDeleteAssessmentModal(false)}
        open={showDeleteAssessmentModal}
        onSubmit={() => deleteAssessmentAPI()}
        contentData={ModalContentAssessment()}
      />
      <ModalDialog
        handleClose={() => setShowCompleteModal(false)}
        open={showCompleteModal}
        onSubmit={() => {
          // setUpdateAssessmentStatus()
          updateAssessment("Completed")
        }
        }
        contentData={ModalContentCompleteAssessment()}
      />
      <ExtendDeadlineModel

        handleClose={() => setShowExtendsDeadlineModal(false)}
        open={showExtendsDeadlineModal}
        onSubmit={() => extendsDeadlineDateAPI()}
        deadline={deadline}
        // contentData={ModalContentExtendDeadlineDate(deadline)}
        handleExtendDeadlineChange={handleExtendDeadlineChange}
        getCurrentDate={getCurrentDate}
      />
      {/* <ApproveDeclineAssesmentModel
        handleClose={() => setShowAcceptDeclineModal(false)}
        open={showAcceptDeclineModal}
        assessmentApprovedDeclinedStatus={updateAssessmentStatus}
        onSubmit={() => updateAssessment("")}
        handleAssesmentStatusChange={handleAssesmentStatusChange}
      /> */}
      {sendAssessmentModal ? (
        <SendAssessmentModal
          handleClose={() => setSendAssessmentModal(false)}
          open={sendAssessmentModal}
          data={null}
        />
      ) : null}
    </>
  );
}
