import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import PropTypes from "prop-types";
import "./css/questionnaire.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { TextareaAutosize } from "@mui/base";
import Dialog from '@mui/material/Dialog';
import MailSend from "../assets/mailsend.svg";
import { useSnackbar } from 'notistack';
import { Link, useLocation, useParams } from "react-router-dom";
import ModalDialog from "./ModalDialog.js";
import FilterIco from '../assets/filter-icon.svg';
import PostRequest from "../components/apiConnections/postRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeEcosystemTab } from "../redux/activeTabSlice";
import BackArrow from '../assets/back-arrow.svg';
import AiFill from '../assets/ai-fill-btn.svg';
import IconAlert from '../assets/icon-alert.svg';
import DefaultQuestionnaireLogo from '../assets/questionnaire-image.png';

import {
  MenuItem,
  Grid,
  Tabs,
  Tab,
  Container,
  Typography,
  Card,
  Box,
  TextField,
  Button,
  Tooltip,
  Drawer,
  CardMedia,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MappedIssue from "./MappedIssue.js";
import SendArrow from '../assets/top-arrow-btn.svg';
import AnimateIcond from '../assets/animate-send.gif';

import GetRequest from "../components/apiConnections/getRequest.js";
import AILoader from "../AILoader.js";
import { updateSubscriptionDetails } from "../redux/subscriptionDetailsSlice.js";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Questionnaire({ theme }) {
  const [value, setValue] = React.useState(0);
  const data = useParams();
  const dispatch = useDispatch();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [logoLink, setLogoLink] = useState();
  const handleOpen = () => {
    if (filteredQuestions.length === 0) {
      enqueueSnackbar("No questions selected. Please add questions before proceeding.", { variant: 'warning' });
      return;
    }
    setOpen(true);
  }

  const handleClose = () => setOpen(false);
  const [showLoader, setshowLoader] = useState(false);
  const [AIFillLoader, setshowAILoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [assessmentData, setAssessmentData] = useState(window.localStorage.getItem("assessmentData"));
  const [questions, setQuestions] = useState([]);
  const [queData, setQueData] = useState(JSON.parse(window.localStorage.getItem("assessmentData")));
  const [ques, setQues] = useState({});
  const [openPop, setOpenPop] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [filter, setFilter] = useState({});
  const [aiFilled, setAiFilled] = useState(false);
  const [questionnaireSubmittedId, setQuestionnaireSubmittedId] = useState('');
  const [issues, setIssues] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(-1);
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };
  const [mappedIssue, setMappedIssue] = useState(null); // State to store the mapped issue

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [successData, setSuccessData] = useState({})
  const [issueMappingData, setIssueMappingData] = useState([]);
  const [complianceMappingData, setComplinceMappingData] = useState([]);
  const [sendAssessmentLoader, setSendAssessmentLoader] = useState(false)
  const handleClickOpen = () => {
    setShowConfirmModal(true);
    ;
  };

  const handleCloseNew = () => {
    // setOpenPop(false);
  };

  useEffect(() => {
    // getMappingDataAPI();
    getQuestionnaire();
    setAiFilled(false);
    getVendorIssue();
    getIssueForQuestionnaire();
    getComplainceMapping();
  }, [])

  useEffect(() => {
    if (aiFilled) {
      handleClearAiFill();
      setAiFilled(false);
    }
    const filteredData = applyFilters()
    setFilteredQuestions(filteredData);
  }, [filter])

  const applyFilters = () => {
    // If no filters are applied, return all questions
    if (Object.keys(filter).length === 0) {
      return questions;
    }
    return questions
      .map(category => {
        // Collect questions matching any of the filters
        const filteredQuestions = category.question?.filter(question => {
          // Check if the question matches any filter
          return Object.keys(filter).some(key => {
            return question.compliances?.some(compliance => {
              return compliance.name === key && filter[key].includes(compliance["Control Id"]);
            });
          });
        });
        // Include the category only if it has matching questions
        if (filteredQuestions && filteredQuestions.length > 0) {
          return {
            ...category,
            question: filteredQuestions,
          };
        }
        return null;
      }).filter(category => category?.question.length > 0);
  };

  useEffect(() => {
    if (assessmentData?.setId) {
      getQuestionairelogoLink(assessmentData?.setId);
    }
  }, [assessmentData]);

  const getQuestionairelogoLink = async (setId) => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"get-questionnaire-logo-link-by-set-id"}?id=${setId}`,
          {}
        )
          .then((data) => {
            setLogoLink(data?.data?.data?.logo)
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" })
            return false;
          })
      }
    } catch (error) {
      return false;
    }
  };

  const getVendorIssue = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ISSUES}?domain=${vendorUrl}`,
        )
          .then((res) => {
            let response = res?.data?.data;
            setIssues(response[0])
          })
          .catch((err) => {
            setshowLoader(false);
          })
      } else {
        window.location.href = "/";
      }
    }
    catch (error) {
      // enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
      setshowLoader(false);
    }
  }

  const aiFillAndSend = async () => {
    if (filteredQuestions.length === 0) {
      enqueueSnackbar("No questions selected. Please add questions before proceeding.", { variant: 'warning' });
      return;
    }
    const selectedQuestions = getFilteredQuestions(filteredQuestions);
    let payload = {
      set: queData.set,
      url: vendorUrl,
      selectedQuestions
    };
    setshowAILoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"ai-fill-questionnaire"}`,
          payload,
        )
          .then((resultData) => {
            enqueueSnackbar(resultData.data.message, { variant: 'success' })
            setAiFilled(true);
            const data = resultData.data.data;
            // setAiFilledQuestions(resultData?.data?.data?.questionnaires)
            setQuestionnaireSubmittedId(resultData?.data?.data?._id)
            const updatedQuestions = updateAnsweredQuestions(filteredQuestions, resultData?.data);
            setFilteredQuestions(updatedQuestions); // Update state with filtered questions
            setshowAILoader(false);
          })
          .catch((errChart) => {
            console.log('errChart', errChart)
            enqueueSnackbar(errChart.message, { variant: 'error' })
            setshowAILoader(false);
          })
      }
    }
    catch (error) {
      enqueueSnackbar("Assessment not saved.", { variant: 'error' })
      setshowAILoader(false);
    }
  }

  const do_saved = async () => {
    const selectedQuestions = (!aiFilled) ? getFilteredQuestions(filteredQuestions) : [];
    let payload = {
      firstName: queData.firstName,
      lastName: queData.lastName,
      email: queData.email,
      data_classification: queData.data_classification,
      assess_level: queData.assess_level,
      regulatory_compliance: queData.regulatory_compliance,
      set: queData.set,
      setId: queData.setId,
      setType: queData.setType,
      deadline: queData.deadline,
      reassessmentDates: queData.reassessmentDates,
      createdBy: queData.createdBy,
      url: vendorUrl,
      aiFill: aiFilled,
      questionnaireSubmittedId,
      selectedQuestions
    };
    setSendAssessmentLoader(true)
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_ASSESSMENT_BY_USER}`,
          payload,
        )
          .then((resultData) => {
            setSendAssessmentLoader(false)
            enqueueSnackbar("Assessment Sent successfully.", { variant: 'success' })
            window.localStorage.removeItem('assessmentData');
            const data = resultData.data.data;
            setOpenPop(true);
            setShowConfirmModal(false)
            setSuccessData({ aiFill: data.aiFill, email: data.email, assessmentId: data._id })
            dispatch(updateSubscriptionDetails({ id: "assessments" }));
          })
          .catch((errChart) => {
            setSendAssessmentLoader(false)
            setOpenPop(false)
            enqueueSnackbar(errChart.message, { variant: 'error' })
          })
      }
      else {
        setSendAssessmentLoader(false)
        setshowLoader(false);
        window.location.href = "/";
      }
    }
    catch (error) {
      setSendAssessmentLoader(false)
      enqueueSnackbar("Assessment not saved.", { variant: 'error' })
    }
  }

  const getQuestionnaire = async () => {
    setAssessmentData(JSON.parse(assessmentData));
    const checkSet = JSON.parse(assessmentData);
    let questionnaireSet = '';
    Object.entries(checkSet)?.map((key, val) => {
      if (key[0] == "set") {
        questionnaireSet = key[1];
      }
    })
    setshowLoader(true);
    const payload = {
      set: questionnaireSet,
      setId: checkSet?.setId,
      setType: checkSet?.setType
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpointIssue = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_USER;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpointIssue}`,
          payload
        )
          .then((res) => {
            let response = res?.data?.data;
            setQuestions(response?.data)
            setQues(response);
            setFilteredQuestions(response?.data)
            setTimeout(() => {
              setshowLoader(false);
            }, 1000)
          })
          .catch((err) => {
            enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        window.location.href = "/";
      }
    }
    catch (error) {
      enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
      setshowLoader(false);
    }
  }

  // const getMappingDataAPI = async () => {
  //   setshowLoader(true);
  //   try {
  //     const refreshToken = await PostRequest(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
  //       {},
  //       {},
  //       "refreshToken"
  //     );
  //     if (refreshToken) {
  //       await GetRequest(
  //         `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE_MAPPING_DATA}`,
  //         {}
  //       )
  //         .then((res) => {
  //           let data = res.data.data;
  //           setshowLoader(false);
  //           setMappingData(data);
  //         })
  //         .catch((err) => {
  //           enqueueSnackbar(err.message, { variant: 'error' });
  //           setshowLoader(false);
  //         })
  //     } else {
  //       setshowLoader(false);
  //     }
  //   }
  //   catch (error) {
  //     enqueueSnackbar(error.message, { variant: 'error' });
  //     setshowLoader(false);
  //   }
  // }

  const clearAiFillAPI = async () => {
    setshowLoader(true);
    const payload = {
      questionnaireId: questionnaireSubmittedId,
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_AIFILL_QUESTIONNAIRE}`,
          payload
        )
          .then((res) => {
            setshowLoader(false);
          })
          .catch((err) => {
            console.log('errChart2', err)
            enqueueSnackbar(err.message, { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);
    }
  }

  const getMappedIssueWithQuestionnaire = (key) => {
    const issueName = key?.issueName?.trim().toLowerCase();
    const categories = ['dns', 'breaches', 'encryption', 'misconfiguration']; // Add all potential categories

    if (!issueName || !issues?.vendorInfo) {
      return []; // Early exit if issueName or vendorInfo is missing
    }
    const matchingIssues = categories.reduce((acc, category) => {
      const issuesInCategory = issues?.vendorInfo[category]; // Get issues for the current category

      // Check if the category has issues and filter for matches
      if (Array.isArray(issuesInCategory) && issuesInCategory.length > 0) {
        const matches = issuesInCategory.filter(data =>
          data?.Issue?.trim().toLowerCase() === issueName
        );

        // Add the category to each matched issue and accumulate
        matches.forEach(issue => {
          acc.push({
            ...issue,  // Spread existing issue properties
            category // Add the category
          });
        });
      }
      return acc;
    }, []); // Initialize accumulator as an empty array
    return matchingIssues; // Return the array of matching issues
  }

  const getIssueForQuestionnaire = async () => {
    setshowLoader(true);

    const endpoint = process.env.REACT_APP_GET_ISSUE_FOR_QUESTIONNAIRE;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          {}
        ).then((res) => {
          let data = res.data.data;
          setshowLoader(false);
          setIssueMappingData(data);
          // console.log("IssueForQuestionnaire  data ", data);
        })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);
    }
  }
  const getComplainceMapping = async () => {
    setshowLoader(true);

    const endpoint = process.env.REACT_APP_GET_COMPLIANCES;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          {}
        ).then((res) => {
          let data = res.data.data;
          setComplinceMappingData(data);
          // console.log("Compliances Mapping data ", data);
          setshowLoader(false);
        })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={MailSend} alt="icon" />
        <h2 className="send-assessments">Send Assessment to the vendor?</h2>
      </Typography>
    );
  }

  const ModalContentAssessment = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={MailSend} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to send this assessment ?
        </h2>
      </Typography>
    );
  };

  const handleClearFilter = () => {
    setFilter({});
  }

  const handleQuestionPropertyChange = (event) => {
    const { name, value } = event.target;
    setFilter(prevFilters => {
      const updatedFilters = { ...prevFilters };
      if (!(value && value.length > 0)) {
        delete updatedFilters[name];
      } else {
        updatedFilters[name] = value;
      }
      return updatedFilters;
    });
  };

  function getFilteredQuestions(filteredQuestions) {
    let selectedQuestions = [];
    filteredQuestions.forEach((categoryItem, index) => {
      const category = categoryItem.category;
      categoryItem.question.forEach((questionItem, item) => {
        const selectedQuestion = {
          category: category,
          question: {
            uniqueId: uniqueIdGenerator(index, item),
            text: questionItem.text ? questionItem.text : (assessmentData.setType == "default") ? questionItem : "",
            impactOnClient: questionItem.impactOnClient,
            impactOnVendor: questionItem.impactOnVendor,
            issueName: questionItem.issueName,
            compliances: questionItem.compliances,
            optionType: questionItem.optionType ? questionItem.optionType : (assessmentData.setType == "default") ? "SCQ" : "",
            options: questionItem.options ? questionItem.options : (assessmentData.setType == "default") ? [{ title: "Yes", score: 5 }, { title: "No", score: 0 }, { title: "N/A", score: 2 }] : [],
            riskMapping: questionItem.riskMapping,
            riskCategory: questionItem?.riskCategory,
          }
        };

        selectedQuestions.push(selectedQuestion);
      });
    });

    return selectedQuestions;
  }

  const uniqueIdGenerator = (index, item) => {

    let questionNo = `${index}${item}`;
    let set = (queData.set).slice(0, 2).toUpperCase();

    function getTwoRandomAlphanumerics() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#';
      return (
        chars.charAt(Math.floor(Math.random() * chars.length)) +
        chars.charAt(Math.floor(Math.random() * chars.length))
      );
    }

    let alphaNumericId = set + getTwoRandomAlphanumerics();
    let uniqueId = `FC-${questionNo}${alphaNumericId}`;

    return uniqueId;
  }

  const updateAnsweredQuestions = (questions, aiResponse) => {
    const aiQuestions = aiResponse.data.questionnaires;
    return questions?.map((categoryObj) => {

      const category = categoryObj.category;
      const aiCategory = aiQuestions[category];
      if (aiCategory) {
        const updatedQuestions = categoryObj.question?.map((question) => {
          const aiQuestion = aiCategory.find((aiQueObj) => aiQueObj.que.text === question.text);
          if (aiQuestion) {
            return {
              ...question,
              answer: aiQuestion.ans || "",
              remark: aiQuestion.remark || "",
              selectedOptions: aiQuestion.selectedOptions || [],
              document_name: aiQuestion.document_name || "",
              documentName: aiQuestion.documentName || "",
              page_number: aiQuestion.page_number || "",
              reference: aiQuestion.reference || "",
            };
          }
          return question;
        });
        return {
          ...categoryObj,
          question: updatedQuestions,
        };
      }

      return categoryObj;
    });
  };

  const handleClearAiFill = () => {
    clearAiFillAPI()
    const questions = filteredQuestions.map((questionSet) => ({
      ...questionSet,
      question: questionSet.question.map((q) => {
        const { documentName, document_name, page_number, reference, remark, answer, selectedOptions, ...filteredQuestion } = q;
        return filteredQuestion;
      })
    }));
    setFilteredQuestions(questions);
    setAiFilled(false);
  }

  const renderMappedIssue = (key) => {
    const issue = getMappedIssueWithQuestionnaire(key); // Call the function to get the mapped issue

    if (issue && issue.length > 0) {
      return (
        <div className="mapping-issue">
          <img src={IconAlert} alt="icon" /> Issue Mapped
        </div>
      );
    }
    return null; // Return null if no issue
  };

  const handleFinalClick = () => {
    console.log(location?.state?.url,)
    if (location?.state?.url) {
      navigate(`${location?.state.url}`, { state: { mainTabValue: 2 } });
    } else {
      navigate(`/dashboard/assessment`);
    }
  }
  return (
    <>
      <Loader show={showLoader} />
      {AIFillLoader ? (
        <AILoader show={AIFillLoader} />
      ) : null}
      <Container maxWidth={false}>
        <Grid item xs={12} md={12} lg={12}>
          <div className="flex-qustionnire-property">
            <Typography variant="h4" sx={{ mb: 0 }}>
              <span className='backlink'>
                {/* <Link to={``} className='none-line'> */}
                <img src={BackArrow} alt='icon' onClick={(e) => { aiFilled && handleClearAiFill(); navigate(`../vendor-information/${vendorUrl}`, { state: location?.state }) }} />
                {/* </Link> */}
                Questionnaire
              </span>
            </Typography>
            <Button
              variant="contained"
              className="questionnnire-send-assessment"
              onClick={handleClickOpen}
            >
              <img src={SendArrow} className="send-arrow-icon" /> Send Assessment
            </Button>
          </div>
        </Grid>

        {/* Filter  */}
        <Grid item xs={12} md={12} lg={12}>
          <div className="flex-from-group">
            <div style={{ display: 'flex', width: "80%", gap: "20px" }}>

              {complianceMappingData && complianceMappingData.length && complianceMappingData.map((compliance) => {
                return (
                  <FormControl>
                    <TextField
                      select
                      fullWidth
                      name={compliance.name}
                      label={compliance.name}
                      value={filter[compliance.name] || []}
                      onChange={handleQuestionPropertyChange}
                      SelectProps={{
                        multiple: true, // Enable multiple selections
                        renderValue: (selected) => {
                          return selected?.map((value) => {
                            const selectedItem = compliance.controls.find(item => item?.["Control Id"] === value);
                            return selectedItem ? `${selectedItem?.["Control Id"]} ` : value;
                          }).join(', ');
                        },
                      }}
                      inputProps={{ 'aria-label': 'Select ISO 27001 Control' }}
                      InputLabelProps={{
                        shrink: Boolean(filter?.[compliance.name]?.length && filter?.[compliance.name]?.length !== 0),
                      }}

                    >
                      <MenuItem value="" disabled>
                        Select {compliance.name}
                      </MenuItem>
                      {Array.isArray(compliance.controls) ? compliance.controls?.map((item, index) => (

                        <MenuItem key={index} value={item?.["Control Id"]}>
                          <Tooltip title={item.Description} arrow>
                            <span>
                              {item?.["Control Id"]} ({item.Name?.length > 40 ? `${item.Name?.substring(0, 40)}...` : item.Name})
                            </span>
                          </Tooltip>
                        </MenuItem>
                      )) : null}
                    </TextField>
                  </FormControl>
                )
              }
              )}
            </div>
            <Button
              variant="contained"
              className="clear-filter"
              onClick={handleClearFilter}
            >
              Clear Filter
            </Button>
          </div>
        </Grid>
        <div className="cybersecurity-assessment-box">
          <p>
            <span className="dpcyber">
              {logoLink ?
                <img className="logo-image-vendor attack-surface-list-logo" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${logoLink}`} alt='logo' />
                :
                <CardMedia
                  component="img"
                  sx={{
                    objectFit: "cover",
                    mx: 'auto',
                    borderRadius: "50%"
                  }}
                  image={DefaultQuestionnaireLogo}
                  alt='logo'
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                />
              }
            </span>
            {ques?.set}
          </p>
          {((!aiFilled) ?
            <img src={AiFill} alt="icon" className="ai-fill" onClick={aiFillAndSend} />
            :
            <Button
              className="clear-ai-fill"
              style={{
                backgroundColor: "rgba(239, 21, 21, 0.3)",
                borderRadius: "20px",
                color: "white",
                width: "12%",
              }}
              onClick={handleClearAiFill}
            >
              Clear AI Fill
            </Button>
          )}

        </div>
        <div className="space-questionnaire">
          <div className="tabs-bg top-demo-space w-100-160">
            {questions?.map((element, index, array) => {
              <Tab label={'test'} key={index}  {...a11yProps(`tab11`)} />
            })}
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {
                filteredQuestions?.map((tab, index) => (
                  <Tab
                    key={tab.category.toString() + index}
                    label={tab.category}
                    {...a11yProps(index)}
                  />
                ))
              }
            </Tabs>
          </div>
          <div className="tabs-bg top-demo-space w-100-cover">
            {(questions && questions?.length > 0) ? (
              <div>
                {(filteredQuestions && filteredQuestions?.length > 0) ? (
                  <Box>
                    {filteredQuestions?.map((tab, index) => (
                      <TabPanel key={index} value={value} index={index}>
                        <div className="question-part alignciq-question">

                          {/* setType = default */}
                          {assessmentData?.setType == 'default' ?
                            <div className="ciq-space">
                            {tab.question?.map((key, val) => (
                              <div key={val}>
                                <div style={{ display: 'flex' }}>
                                  <p className="preview-question-heading">Q. {val + 1}</p>
                                  <span className="data-text-preview">{key}</span>
                                </div>
                                {/* <QuestionCategoryInfo data={key} marginLeftTrue={true}/> */}
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      value="#"
                                      control={<Radio />}
                                      label="Yes"
                                      disabled
                                    />
                                    <FormControlLabel
                                      value="#"
                                      control={<Radio />}
                                      label="No"
                                      disabled

                                    />
                                    <FormControlLabel
                                      value="#"
                                      control={<Radio />}
                                      label="N/A"
                                      disabled
                                    />
                                  </RadioGroup>
                                </FormControl>
                                <p>Write here</p>
                                <TextareaAutosize
                                  aria-label="Write here"
                                  minRows={3}
                                  className="w100-textarea"
                                  disabled
                                  defaultValue={key?.remark || ""}
                                />
                              </div>
                            ))}

                            </div>
                     
                          :(assessmentData?.setType == 'custom' || assessmentData?.setType == "customAdmin") ? <div>
                                   {/* setType customAdmin  and custom */}
                            {tab.question?.map((key, val) => (
                              <div key={val} className="multiple-questionniareloop">
                                {renderMappedIssue(key)}
                                <div className="flex-preview-tab que-indexing">
                                  <div style={{ display: 'flex' }}>
                                    <p className="preview-question-heading">Q. {val + 1}</p>
                                    <span className="data-text-preview">{key.text}</span>
                                  </div>
                                  {/* Mapped Issue  */}
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      toggleDrawer(val)();
                                      const mappedIssue = getMappedIssueWithQuestionnaire(key); // Await the result
                                      setMappedIssue(mappedIssue); // Set the resolved value to state
                                    }}
                                  >
                                    <img src={FilterIco} alt="icon" />
                                  </span>
                                  <Drawer anchor="right" open={(openDrawer === val)} onClose={toggleDrawer(-1)}>
                                    <MappedIssue
                                      data={key}
                                      issueMapped={mappedIssue}
                                    />
                                  </Drawer>

                                </div>
                                {/* <QuestionCategoryInfo data={key} marginLeftTrue={true}/> */}
                                <div className="options-flex">
                                  {((!aiFilled) ? (
                                    <>
                                      {/* Not AI Filled  */}
                                      {key.options?.map((option, index) => (
                                        <div className="flex-radio-check" key={index}>
                                          <p>
                                            <FormControlLabel
                                              value="#"
                                              control={key.optionType == 'MCQ' ? <Checkbox /> : <Radio />}
                                              label=""
                                              disabled
                                            />
                                          </p>
                                          <span className="data-text-preview">{option.title}</span>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <div>
                                      {/*  AI filled MCQ  */}
                                      {key?.optionType === "MCQ" ? (
                                        <div className="checkbox-view-answer">
                                          <FormControl>
                                            {key?.options?.map((option, j) => {
                                              return (
                                                <FormControlLabel
                                                  value={j + 1}
                                                  control={
                                                    <Checkbox
                                                      checked={key.answer?.includes(
                                                        `${j + 1}`
                                                      )}
                                                    />
                                                  }
                                                  disabled={true}
                                                  label={option.title}
                                                // onChange={(e) =>
                                                //   questionnaireAnswerConfig(e, i)
                                                // }
                                                // disabled={submitted ? true : false}
                                                />
                                              );
                                            })}
                                          </FormControl>
                                        </div>
                                      ) : (
                                        //  AI Filled SCQ 
                                        <div>
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            defaultValue={key?.answer}
                                          >
                                            {key?.options?.map((option, j) => (
                                              <FormControlLabel
                                                key={j}
                                                value={option.title}
                                                control={<Radio />}
                                                label={option.title}
                                                disabled={true}
                                              />
                                            ))}
                                          </RadioGroup>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                  }
                                </div>
                                <div className="flex-preview-tab">
                                  {(aiFilled ? (
                                    <>
                                      <TextareaAutosize
                                        aria-label="Write here  "
                                        minRows={3}
                                        className="textarea-auto-size-answered"
                                        key={aiFilled ? "aiFilled" : "notAiFilled"} // Changing key forces re-render
                                        //  defaultValue={key.remark || ""}
                                        defaultValue={key.remark ? key.remark.split("Document")[0].trim() : ""}
                                        readOnly
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <TextareaAutosize
                                        aria-label="Write here"
                                        placeholder="Type your message here..."
                                        minRows={3}
                                        className="textarea-auto-size"
                                        disabled
                                        key={aiFilled ? "aiFilled" : "notAiFilled"} // Changing key forces re-render
                                      />
                                    </>
                                  ))}
                                  {/* <AttachFileIcon className="space-file" /> */}
                                </div>
                              </div>
                            ))}
                          </div> : null
                          }
                        </div>
                      </TabPanel>
                    ))}
                  </Box>
                ) : (
                  <Card sx={{ padding: "10px", "margin": "10px" }}>
                    <p>Questionnaire Not Found !</p>
                  </Card>
                )}
              </div>
            ) : (
              <div>
                {/* <Box sx={{ flexGrow: 1, display: "flex" }}> */}
                <Card sx={{ padding: "10px", "margin": "10px" }}>
                  <p>Questionnaire Not Found !</p>
                  {/* </Box>                       */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Link to={`../vendor-information/${vendorUrl}`}>
                      <Button variant="contained" className="click-to-begin m-l cancel-btn">Previous </Button>
                    </Link>
                  </Grid>
                </Card>

              </div>
            )
            }
          </div>
        </div>
        <ModalDialog handleClose={handleClose} open={open} onSubmit={do_saved} contentData={ModalContent} />
      </Container>

      <Dialog open={openPop} onClose={handleCloseNew} className="fullpopup-send-assessment">
        <div className="inner-popup-design">
          <div>
            <img src={AnimateIcond} alt="gif" />
            <h2>Assessment sent!</h2>
            <p>Your assessment has been sent to the vendor. We'll keep you updated on their progress</p>
            <Button onClick={handleFinalClick} className="color-ok-pop">Ok</Button>
          </div>
        </div>
      </Dialog>

      <ModalDialog
        handleClose={() => setShowConfirmModal(false)}
        open={showConfirmModal}
        onSubmit={() => do_saved()}
        loader={sendAssessmentLoader}
        contentData={ModalContentAssessment()}
      />
    </>
  );
}
