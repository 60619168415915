import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Box,
  Card,
  CardHeader,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
import "../css/assessment.css";
import "../css/complianceCard.css";
import SendArrow from '../../assets/top-arrow-btn.svg';
import BackArrow from '../../assets/back-arrow.svg';
import CalendarTodayIcon from '../../assets/calender-icon-select.svg'

export default function ComplianceCard() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [complianceId, setComplianceId] = useState(params?.id);
  const [showLoader, setShowLoader] = useState(false);
  const [invalidComplianceId, setInvalidComplianceId] = useState(false);
  const [complianceType, setComplianceType] = useState(["Others"]);
  const [adminComplianceType, setAdminComplianceType] = useState([]);
  const [data, setData] = useState({
    complianceType: "",
    issueDate: null,
    expiryDate: null,
    description: "",
    issuerName: "",
    name: "",
    logo: null,
    document: null,
  });
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    getAdminComplianceType();
    if (complianceId) getComplianceDetails();
  }, []);

  const handleDrop = (event, fileType) => {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const droppedFile = droppedFiles[0];

      // Update the state or perform any additional actions based on fileType
      if (fileType === "document") {
        setData({ ...data, document: droppedFile });
      } else if (fileType === "logo") {
        setData({ ...data, logo: droppedFile });
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleChange = (e) => {
    let event = e.target;
    if (event.name == "logo" || event.name == "document") {
      setData({ ...data, [event.name]: event.files[0] });
    } else if (event.name == "complianceType") {
      if (event.value !== "Others") {
        setData({
          ...data,
          complianceType: event.value,
          name: event.value,
          logo: adminComplianceType.find((item) => item.name == event.value)
            ?.logo,
        });
      } else {
        setData({ ...data, complianceType: event.value, name: "", logo: "" });
      }
    } else setData({ ...data, [event.name]: event.value });
  };

  const getComplianceDetails = async () => {
    setShowLoader(true);
    const endpoint = process.env.REACT_APP_GET_COMPLIANCE_LIST_BY_VENDOR;
    try {
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?complianceId=${complianceId}`
      )
        .then((resVendors) => {
          let arrResponse = resVendors.data.data;
          if (arrResponse?.length)
            setData({
              ...arrResponse[0],
              issueDate: moment(arrResponse[0]?.issueDate).format("YYYY-MM-DD"),
              expiryDate: moment(arrResponse[0]?.expiryDate).format(
                "YYYY-MM-DD"
              ),
            });
          setShowLoader(false);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setShowLoader(false);
          setInvalidComplianceId(true);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
      setInvalidComplianceId(true);
    }
  };

  const getAdminComplianceType = async () => {
    setShowLoader(true);
    const endpoint = process.env.REACT_APP_GET_ADMIN_COMPLIANCE_TYPE;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            if (arrResponse?.length) {
              setAdminComplianceType(arrResponse);
              setComplianceType([
                ...complianceType,
                ...arrResponse.map((item) => item.name),
              ]);
            }
            setShowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
            setInvalidComplianceId(true);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
      setInvalidComplianceId(true);
    }
  };

  const uploadCompliance = async () => {
    setShowLoader(true);
    let formData = new FormData();
    if (complianceId) formData.append("complianceId", complianceId);
    formData.append("emailId", localStorage.getItem("userEmail"));
    formData.append("complianceType", data.complianceType);
    formData.append("issueDate", data.issueDate);
    formData.append("expiryDate", data.expiryDate);
    formData.append("description", data.description);
    formData.append("issuerName", data.issuerName);
    formData.append("name", data.name);
    formData.append("logo", data.logo);
    formData.append("document", data.document);

    const endpoint = process.env.REACT_APP_UPLOAD_COMPLIANCE_CARD;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          formData
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            enqueueSnackbar(resVendors.data.message, { variant: "success" });
            setShowLoader(false);
            console.log(arrResponse);
            navigate(`/vendor/compliance`);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const checkValidation = () => {
    uploadCompliance();
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h4" sx={{ mb: 0 }}>
          <span className='backlink'>
            <img src={BackArrow} onClick={() => navigate(-1)} alt='icon' />
            Compliance
          </span>
        </Typography>
      </Grid>
      {invalidComplianceId ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography>Invalid Compliance</Typography>
        </Box>
      ) : (
        <form className="profile-form">
          <Card className="padding-square" sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Choose Compliance
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="complianceType"
                    value={data.complianceType}
                    label="Choose Compliance"
                    onChange={handleChange}
                  >
                    {complianceType?.map((name) => (
                      <MenuItem value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="issueDate"
                  label="Issue Date"
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                  value={data.issueDate || ""}
                  onChange={(event) => handleChange(event)}
                  sx={{ color: 'white' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={CalendarTodayIcon}
                          alt="calendar icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            document.getElementsByName(`issueDate`)[0].showPicker();
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  name="expiryDate"
                  label="Expiry Date"
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                  value={data.expiryDate || ""}
                  onChange={(event) => handleChange(event)}
                  inputProps={{
                    min: data.issueDate,
                  }}
                  sx={{ color: 'white' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={CalendarTodayIcon}
                          alt="calendar icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            document.getElementsByName(`expiryDate`)[0].showPicker();
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextareaAutosize
                  name="description"
                  value={data.description}
                  fullWidth
                  aria-label="empty textarea"
                  placeholder="Description"
                  minRows={4}
                  className="fullWidthTextarea"
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="center-upload  document-preview"
                onDrop={(e) => handleDrop(e, "document")}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <CardHeader title={"Upload your compliance"} sx={{ pl: 0 }} />
                <Button
                  htmlFor="file-upload"
                  size="small"
                  variant="contained"
                  color="primary"
                  component="label"
                  className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                    }`}
                  style={{ width: "100%", height: "100px" }}
                  title="Select File"
                >
                  {data.document ? (
                    <div>
                      {typeof data?.document == "string"
                        ? data.document
                        : data.document?.name}
                    </div>
                  ) : (
                    <>
                      <span style={{ color: "#007bff" }}>Upload Document </span>
                      &nbsp; or Just Drag and Drop
                    </>
                  )}
                </Button>
                <input
                  name="document"
                  id="file-upload"
                  type="file"
                  style={{ display: "none", width: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={data.complianceType !== "Others"}
                  name="name"
                  placeholder="Compliance Name"
                  sx={{ pb: 2 }}
                  value={data.name}
                  onChange={handleChange}
                  id="custom-input"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="issuerName"
                  placeholder="Issuer Name"
                  sx={{ pb: 2 }}
                  value={data.issuerName}
                  onChange={handleChange}
                  id="custom-input"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="center-upload logo-preview"
                onDrop={(e) => handleDrop(e, "logo")}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <CardHeader title={"Upload Logo"} sx={{ pl: 0 }} />
                <Button
                  htmlFor="logo-upload"
                  size="small"
                  variant="contained"
                  color="primary"
                  component="label"
                  className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                    }`}
                  style={{ width: "100%", height: "100px", cursor: data.complianceType !== "Others" ? "not-allowed" : "" }}
                  title="Select Image"
                >
                  {data.logo ? (
                    <div>
                      {data.complianceType == "Others" ? (
                        <img
                          className="preview-image"
                          src={
                            typeof data?.logo == "string"
                              ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_LOGO}/${data?.logo}`
                              : URL.createObjectURL(data?.logo)
                          }
                          alt="icon"
                        />
                      ) : (
                        <img
                          className="preview-image"
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_ASSETS}/${data?.logo}`}
                          alt="icon"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <span style={{ color: "#007bff" }}>Upload Logo</span>
                      &nbsp; or Just Drag and Drop
                    </>
                  )}
                </Button>

                <input
                  disabled={data.complianceType !== "Others"}
                  name="logo"
                  id="logo-upload"
                  type="file"
                  accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                  style={{ display: "none", with: "100%" }}
                  onChange={handleChange}
                />
              </Grid>
              <div className="btn-arrange">
                <Link to={`../compliance`}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    component="button"
                    type=""
                    className="submit-btn-comment"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  className="submit-btn-comment"
                  onClick={(e) => {
                    checkValidation();
                  }}
                >
                  <img src={SendArrow} className="send-arrow-icon" /> Upload
                </Button>
              </div>
            </Grid>
          </Card>
        </form>
      )}
    </>
  );
}
