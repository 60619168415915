import React, { useState, useEffect } from "react";
import "./css/VendorInformation.css";
import { Helmet } from "react-helmet-async";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PostRequest from "../components/apiConnections/postRequest";
import GetRequest from "../components/apiConnections/getRequest";
import { getLocalStoarge } from "../components/common/allFunctions";
import { useDispatch } from "react-redux";
import CalendarTodayIcon from '../assets/calender-icon-select.svg';
import {
  Grid,
  Container,
  TextField,
  Button,
  Card, Box, CardMedia,
  Typography,
  InputAdornment
} from "@mui/material";
import Loader from "../Loader";
import DefaultQuestionnaireLogo from '../assets/questionnaire-image.png';
import moment from "moment";
import BackArrow from '../assets/back-arrow.svg';
import AddNewIcon from '../assets/add-calender-new.svg';
import CrossDates from '../assets/cross-dates.svg';
import TickSelect from '../assets/tick-select.svg';

export default function VendorInformation() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useParams();
  const [showLoader, setshowLoader] = useState(false);
  const [selectSet, setSelectSet] = useState('');
  const [selectSetId, setSelectSetId] = useState('')
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [deadline, setDeadline] = useState('');
  const [reassessmentDates, setReassessmentDates] = useState([])
  const [userEmail, setUserEmail] = useState(getLocalStoarge("userEmail"));
  const [checkData, setCheckData] = useState(JSON.parse(window.localStorage.getItem("assessmentData")) || '');
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [setsNames, setSetsNames] = useState([]);
  const [contactPersonList, setContactPersonList] = useState([])

  const [selectedContactPerson, setSelectedContactPerson] = useState('')
  let data_classification_new = [];
  let assess_level_new = [];
  let regulatory_compliance_new = [];
  if (checkData !== "") {
    data_classification_new = checkData?.data_classification.split(',');
    assess_level_new = checkData?.assess_level.split(',');
    regulatory_compliance_new = checkData?.regulatory_compliance.split(',');
  }
  const [data_classification, setDataClassification] = useState(data_classification_new || []);
  const [assess_level, setAccessLevel] = useState(assess_level_new || []);
  const [regulatory_compliance, setRegulatory] = useState(regulatory_compliance_new || []);
  useEffect(() => {
    getSetName();
    getContactPerson();
    if (checkData !== "") {
      setFirstName(checkData.firstName);
      setLastName(checkData.lastName);
      setEmail(checkData.email);
      setDeadline(checkData.deadline);
      setSelectSetId(checkData.setId);
      setReassessmentDates(checkData.reassessmentDates || []);
    }
  }, [])
 
  const handleValid = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    }
    if (name === "lastName") {
      setLastName(value);
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "deadline") {
      setDeadline(value);
    }
  };

  const getSetName = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SETS_NAMES}`,
        )
          .then((resSetsName) => {
            const obj = resSetsName.data.data;
            setSetsNames(obj);
            if (checkData.setId && obj?.length) {
              let selectedSet = obj?.find((set) => set._id == checkData.setId)
              if (selectedSet?._id) setSelectSet(JSON.stringify(selectedSet))
            }
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Sets Name not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Sets Name not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getContactPerson = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`,
        )
          .then((res) => {
            const response = res.data.data;
            setContactPersonList(response);
            if (checkData.email && Array.isArray(response)) setSelectedContactPerson(JSON.stringify(response?.find((item) => item.email == checkData.email)))
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Contact person not found", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      enqueueSnackbar("Contact person not found", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const do_submit = async (e) => {
    e.preventDefault();
    if (!selectedContactPerson) {
      enqueueSnackbar('Please select contact person', { variant: 'error' })
      return false;
    }
    let contactPerson = JSON.parse(selectedContactPerson)
    let currentTime = Date.now();
    let todayEpoch = new Date(currentTime).getFullYear() + "-" + ((new Date(currentTime).getMonth() + 1) < 10 ? ("0" + (new Date(currentTime).getMonth() + 1)) : (new Date(currentTime).getMonth() + 1)) + "-" + ((new Date(currentTime).getDate() < 10) ? ("0" + (new Date(currentTime).getDate())) : new Date(currentTime).getDate());
    if ((!deadline) || deadline === null || deadline === '' || (todayEpoch > deadline) || (deadline.length !== 10)) {
      enqueueSnackbar("Please select valid deadline date.", { variant: 'error' });
      setDeadline('');
      return false;
    }
    if ((!selectSet) || selectSet === null || selectSet === '') {
      enqueueSnackbar("Please select Set.", { variant: 'error' })
      setSelectSet('');
      return false;
    }
    setshowLoader(true);
    const filteredData = reassessmentDates.filter(item => item.date !== null && item.deadline !== null);
    setReassessmentDates(filteredData); 
    let payload = {
      firstName: contactPerson?.firstName || "",
      lastName: contactPerson?.lastName || "",
      email: contactPerson?.email,
      data_classification: data_classification.toString(),
      assess_level: assess_level.toString(),
      regulatory_compliance: regulatory_compliance.toString(),
      set: JSON.parse(selectSet).set,
      setType: JSON.parse(selectSet).type,
      setId: selectSetId,
      deadline,
      reassessmentDates:filteredData,
      createdBy: userEmail,
      url: data.url
    };
    window.localStorage.setItem("assessmentData", JSON.stringify(payload));
    setshowLoader(false);
    navigate(`../questionnaire/${vendorUrl}`, { replace: true, state: location?.state });
  };

  const getCurrentDate = (date) => {
    let parseDate = date
    if(date !== deadline) parseDate = moment(parseDate).add(1,'day')
    let currentDate = new Date(parseDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleContactPersonChange = (e) => {
    setSelectedContactPerson(e.target.value)
  }

  const handleAddReassessmentDate = () => {
    const length = reassessmentDates.length;
    if (length === 3) {
      enqueueSnackbar("Only three re-assessment date allowed", { variant: "warning" })
      return;
    }
    if (!deadline) {
      enqueueSnackbar("Please select deadline", { variant: "warning" })
      return;
    }
    if (length && !reassessmentDates[length - 1]?.deadline) {
      enqueueSnackbar(`Please select deadline for ${length == 1 ? "first" : "second"} reassessment`, { variant: "warning" })
      return;
    }
    setReassessmentDates([...reassessmentDates.slice(0), { date: null, deadline: null }])
  }
  
  const handleRemoveReassessmentDate = (indexToRemove) => {
    const updatedDates = reassessmentDates.filter((_, index) => index !== indexToRemove);
    setReassessmentDates(updatedDates);
  };
  
  const handleBackClick = () => {
    navigate(location?.state?.url || location?.state || `/dashboard/assessment`, { state: { mainTabValue: 2 } })
  }
  return (
    <>
      <Loader show={showLoader} />

      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
       <Grid container spacing={3} className="ipad-space">
         <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" sx={{ mb: 0 }}>
           <span className='backlink'>
            <img src={BackArrow} onClick={() =>handleBackClick()} alt='icon' />
            Vendor Information</span>
           </Typography>
          </Grid>
    
           <Grid item xs={12} md={12} lg={12}>
               <div className="d-flex-back-btn">
               
               </div>
            <form onSubmit={(e) => { do_submit(e) }} >
             <div className="vendor-information">
              <Grid container spacing={3} className="border-bottom">
               <Grid item xs={12} md={12} lg={12}>
                <h3 className="vendor-contact-heading">Vendor's Contact Person</h3>
                </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">{!selectedContactPerson ? "Select Contact Person" : ""}</InputLabel>
                    <TextField
                    labelId="demo-simple-select-label"
                    select
                    defaultValue=""
                    className="contact-person"
                    value={selectedContactPerson || ""}
                    onChange={handleContactPersonChange}
                  >
                    {
                      contactPersonList.map((item, val) => (
                        <MenuItem key={item._id} value={JSON.stringify(item)} >{item.firstName} {item.lastName} {`(${item.email})`}</MenuItem>
                      ))
                    }
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  name="deadline"
                  InputLabelProps={{ shrink: true, required: true }}
                  // placeholder={date}
                  type="date"
                  value={deadline || ""}
                  title="Select Date"
                  className="contact-person"

                  onChange={(event) => handleValid(event)}
                  label="Deadline"
                  // Add min attribute to disable past dates
                  inputProps={{ min: getCurrentDate(new Date()), onKeyDown: (e) => e.preventDefault() }}
                  sx={{ color: 'white' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={CalendarTodayIcon}
                          alt="calendar icon"
                          style={{ cursor: 'pointer' }}							 
                          onClick={() => {
                            document.getElementsByName('deadline')[0].showPicker();
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                  </Grid>
                </Grid>
                
               <Grid container spacing={3} className="re-assessment-date">
                {reassessmentDates?.map((item1, i) => (
                  <>
                  <Grid item xs={12} md={12} lg={12}>
                   <h3 className="vendor-contact-heading">Re-assessment Dates</h3>
                   </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                       name={`reassessmentDate-${i}`}
                        InputLabelProps={{ shrink: true, required: true }}
                        // placeholder={date}
                        type="date"
                        value={item1.date || ""}
                        className="contact-person"
                        title="Select Date"
                        onChange={(event) => {
                          let dates = [...reassessmentDates]
                          dates[i].date = event.target.value;
                          setReassessmentDates(dates)
                        }}
                        label="Re-assessment Date"
                        // Add min attribute to disable past dates
                        inputProps={{ min: getCurrentDate(reassessmentDates[i - 1]?.deadline || deadline), onKeyDown: (e) => e.preventDefault() }}
                        sx={{ color: 'white' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={CalendarTodayIcon}
                                alt="calendar icon"
                                style={{ cursor: 'pointer' }}							 
                                onClick={() => {
                                  document.getElementsByName(`reassessmentDate-${i}`)[0].showPicker();
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        name={`reassessmentDateDeadline-${i}`}
                        className="contact-person"
                        InputLabelProps={{ shrink: true, required: true }}
                        // placeholder={date}
                        type="date"
                        value={item1.deadline || ""}
                        title="Select Date"
                        onChange={(event) => {
                          let dates = [...reassessmentDates]
                          dates[i].deadline = event.target.value;
                          setReassessmentDates(dates)
                        }}
                        label="Deadline"
                        // Add min attribute to disable past dates
                        inputProps={{
                          min: getCurrentDate(reassessmentDates[i].date),
                          onKeyDown: (e) => e.preventDefault(),
                          onClick: (e) => {
                            if(!reassessmentDates[i].date){
                              enqueueSnackbar("Please select Re-assessment Date first",{variant:"warning"})
                              e.preventDefault()
                            } 
                          }
                        }}
                        sx={{ color: 'white' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img
                                src={CalendarTodayIcon}
                                alt="calendar icon"
                                style={{ cursor: 'pointer' }}							 
                                onClick={() => {
                                  document.getElementsByName(`reassessmentDateDeadline-${i}`)[0].showPicker();
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} style={{display:'flex', alignItems:'center'}}>
                     <img src={CrossDates}  onClick={() => handleRemoveReassessmentDate( i )} alt="icon"/> 
                   </Grid>
                  </>
                ))}
                {(reassessmentDates.length < 3) &&
                  <Box sx={{ ml: 'auto', mt: 2 }} >
                    <Button variant="contained" className='reassessments-btn' onClick={handleAddReassessmentDate}>
                    <img src={AddNewIcon} alt="icon"/> Add Reassessments Dates
                    </Button>
                  </Box>
                }
                 </Grid>
              </div>
              <p className="vendor-options-below">Please choose the assessment you need to send from the options below.</p>
              <div className="vendor-information mtop-vendor">
                <FormControl>
                  <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    {/* <h3 class="vendor-information-head"><span>2</span>Assessment</h3> */}
                     <div>
                     
                    </div>
                    <Grid container xs={12} spacing={3} sx={{ mt: 1 }} className="assessment-card">
                      {Array.isArray(setsNames) ? setsNames.map((item, i) => (
                        <Grid item xs={12} md={3} lg={3} key={i}>
                          <Card
                            className={item._id === selectSetId ? "selected-assessment-card" : ""}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectSetId(item._id);
                              setSelectSet(JSON.stringify(item));
                            }}
                            
                          >
                            <CardMedia
                              className="card-media-img"
                              component="img"
                              sx={{
                                objectFit: "cover",
                                width: "80px",
                                height: "80px",
                                mx: 'auto',
                                borderRadius: "50%"
                              }}
                              image={item.logo ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${item.logo}` : DefaultQuestionnaireLogo}
                              alt={item.set}
                              onError={(e) => {
                                e.target.onerror = null;
                                // e.target.src = '/path/to/fallback-image.jpg'; 
                              }}
                             />
                            <Box className="name-optional">{item.set}</Box>
                            <img src={TickSelect} className="tick-selected-icon" alt="icon"/>
                           </Card>
                         </Grid>
                       )) : null}
                     </Grid>
                   </Grid>
                  </FormControl>
              </div>
              <Grid item xs={12} md={12} lg={12} className="btn-right">
                     <Button type="submit" variant="contained" className="next-steps-button">
                      Next Step
                     </Button>
                    {/* </Link> */}
                  </Grid>
              </form>
            </Grid>
          </Grid>
      </Container>

    </>
  );
}

