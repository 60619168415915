import React, { useEffect, useRef } from "react";

import { Card, Grid, } from "@mui/material";
import * as  d3 from "d3";


const BluredSunburstChartD3Older = () => {
  const chartRef = useRef(null);


  const tooltip = d3.select("body")
    .append("div")
    .style("position", "absolute")
    .style("background", "rgba(0, 0, 0, 0.7)")
    .style("color", "white")
    .style("padding", "5px")
    .style("border-radius", "4px")
    .style("pointer-events", "none")
    .style("opacity", 0);


  const width = 1200;
  const height = width;
  const radius = width / 4;

  let data=(
    {
        "name": "Third Party Risk Management",
        "children": [
            {
                "name": "Technology and Telecommunications",
                "children": [
                    {
                        "name": " PaaS",
                        "children": [
                            {
                                "name": "WWE",
                                "domain": "wwe.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Inside Out 0/100",
                                        "children": [
                                            {
                                                "name": "ImpactOn Vendor",
                                                "children": [
                                                    {
                                                        "name": "Fines",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Regulatory scrutiny",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Data breaches",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "ImpactOn Client",
                                                "children": [
                                                    {
                                                        "name": "Legals actions",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Financial penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal liabilities",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            }
                                        ],
                                        "color": "#6C32C2"
                                    },
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Cloud Service Providers (IaaS",
                        "children": [
                            {
                                "name": "WWE",
                                "domain": "wwe.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Inside Out 0/100",
                                        "children": [
                                            {
                                                "name": "ImpactOn Vendor",
                                                "children": [
                                                    {
                                                        "name": "Fines",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Regulatory scrutiny",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Data breaches",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "ImpactOn Client",
                                                "children": [
                                                    {
                                                        "name": "Legals actions",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Financial penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal liabilities",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            }
                                        ],
                                        "color": "#6C32C2"
                                    },
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Software Development Vendors",
                        "children": [
                            {
                                "name": "ASUS",
                                "domain": "asus.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": " SaaS)",
                        "children": [
                            {
                                "name": "WWE",
                                "domain": "wwe.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Inside Out 0/100",
                                        "children": [
                                            {
                                                "name": "ImpactOn Vendor",
                                                "children": [
                                                    {
                                                        "name": "Fines",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Regulatory scrutiny",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Data breaches",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "ImpactOn Client",
                                                "children": [
                                                    {
                                                        "name": "Legals actions",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Financial penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal liabilities",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            }
                                        ],
                                        "color": "#6C32C2"
                                    },
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Digital Marketing and Advertising Tools",
                "children": [
                    {
                        "name": "SEO and SEM Tools",
                        "children": [
                            {
                                "name": "Nokia",
                                "domain": "nokia.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Outside In 0/100",
                                        "children": [],
                                        "color": "#C42FC6"
                                    }
                                ]
                            },
                            {
                                "name": "Impetus",
                                "domain": "impetus.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Marketing Automation Platforms",
                        "children": [
                            {
                                "name": "5Exceptions",
                                "domain": "5exceptions.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            },
                            {
                                "name": "Wildcraft India",
                                "domain": "wildcraft.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Outside In 0/100",
                                        "children": [],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Banking and Financial Services",
                "children": [
                    {
                        "name": "Payment Processors",
                        "children": [
                            {
                                "name": "ICICI",
                                "domain": "icici.com",
                                "color": "#60A0D1",
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Retail Banking Solutions",
                        "children": [
                            {
                                "name": "HDFC",
                                "domain": "hdfc.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Inside Out 14/100",
                                        "children": [
                                            {
                                                "name": "Business Risk",
                                                "children": [
                                                    {
                                                        "name": "Cybersecurity Risk",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Compliance Risk",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Reputational Risk",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "AnyRisk",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "Failed Controls",
                                                "children": [
                                                    {
                                                        "name": "Category 1",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Category 2",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Category 3 ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "Questionnaire",
                                                "children": [
                                                    {
                                                        "name": "SOC QUESTIONAIRE",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "ImpactOn Vendor",
                                                "children": [
                                                    {
                                                        "name": "Fines",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Regulatory scrutiny",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Data breaches",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "ImpactOn Client",
                                                "children": [
                                                    {
                                                        "name": "Legals actions",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Financial penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal liabilities",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            }
                                        ],
                                        "color": "#6C32C2"
                                    },
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            },
                            {
                                "name": "IDFC",
                                "domain": "idfc.com",
                                "color": "#60A0D1",
                                "children": []
                            },
                            {
                                "name": "Etisalat",
                                "domain": "etisalat.ae",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Inside Out 5/100",
                                        "children": [
                                            {
                                                "name": "ImpactOn Vendor",
                                                "children": [
                                                    {
                                                        "name": "Fines",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Regulatory scrutiny",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Data breaches",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Reputation Damage",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Reduced market value",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            },
                                            {
                                                "name": "ImpactOn Client",
                                                "children": [
                                                    {
                                                        "name": "Legals actions",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Financial penalties",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Loss of trust",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Legal liabilities",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Revenue loss",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Operational disruption",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#2A5BC5"
                                            }
                                        ],
                                        "color": "#6C32C2"
                                    },
                                    {
                                        "name": "Outside In 89/100",
                                        "children": [
                                            {
                                                "name": "TopCVEs",
                                                "children": [
                                                    {
                                                        "name": "CVE-2017-15715",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "CVE-2024-40898",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "CVE-2017-3735",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "CVE-2009-3767",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "CVE-2019-0220",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": "Null",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "strict-transport-security",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Encryption",
                                                "children": [
                                                    {
                                                        "name": "Null",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Aviation",
                "children": [
                    {
                        "name": "Cargo and Freight Airlines",
                        "children": [
                            {
                                "name": "UK",
                                "domain": "uk.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Professional Services",
                "children": [
                    {
                        "name": "IT Consulting and Managed Services Providers",
                        "children": [
                            {
                                "name": "Bhutan Tourism",
                                "domain": "bhutan.com",
                                "color": "#60A0D1",
                                "children": [
                                    {
                                        "name": "Outside In 82/100",
                                        "children": [
                                            {
                                                "name": "DNS",
                                                "children": [
                                                    {
                                                        "name": " Invalid SSL/TLS Subject ",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Misconfiguration",
                                                "children": [
                                                    {
                                                        "name": "content-security-policy",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-frame-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-content-type-options",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "x-permitted-cross-domain-policies",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            },
                                            {
                                                "name": "Breaches",
                                                "children": [
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    },
                                                    {
                                                        "name": "Employee Data Breach",
                                                        "value": 1
                                                    }
                                                ],
                                                "color": "#186A5F"
                                            }
                                        ],
                                        "color": "#C42FC6"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
  )

  useEffect(() => {
    if (data) {
      d3.select(chartRef.current).selectAll("*").remove();


      // Compute the layout.
      const hierarchy = d3
        .hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value);

      const root = d3
        .partition()
        .size([2 * Math.PI, hierarchy.height + 1])
        (hierarchy);

      root.each(d => d.current = d);  // Maintain the current node reference for transition

      // Create the color scale.
      const numChildren = hierarchy?.children?.length ?? 0;
      const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, numChildren + 1));
      // const color = d3.scaleOrdinal(d3.schemeCategory10);


      // Create the arc generator.
      const arc = (d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1)))

      // Create the SVG container.
      const svg = (d3
        .select(chartRef.current)
        .append("svg")
        .attr("viewBox", [-width / 2, -height / 2, width, width])
        .style("font", "10px sans-serif"));


      // Appending a circle for demonstration
      svg.append("circle")
        .attr("r", radius)
        .attr("fill", "lightblue")
        .attr("stroke", "black");


      // Append the arcs.
      const path = (svg.append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .join("path")
        .attr("fill", d => d.data.color ? d.data.color : color(d.data.name))
        .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
        .attr("pointer-events", d => arcVisible(d.current) ? "auto" : "none")
        .attr("d", d => arc(d.current))
        // .on("click", (event, d) => {
        //   // Add `onClick` function for every node.
        // })
      );

      // Add Tooltip 
      path.on("mouseover", (event, d) => {
        
        tooltip.style("opacity", 1)
          .html(`
          ${d.data.name}
        `)
          .style("left", (event.pageX + 10) + "px")
          .style("top", (event.pageY + 10) + "px");
      })
        .on("mousemove", (event) => {
          tooltip.style("left", (event.pageX + 10) + "px")
            .style("top", (event.pageY + 10) + "px");
        })
        .on("mouseout", () => {
          tooltip.style("opacity", 0);
        });

      // Make them clickable if they have children.
      path.filter(d => d.children)
        .style("cursor", "pointer")
        .on("click", clicked);

      const label = (svg.append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .join("text")
        .attr("dy", "0.35em")
        .attr("fill-opacity", d => +labelVisible(d.current))
        .attr("transform", d => labelTransform(d.current))
        .attr("fill", "white")
        .text(d => d.data.name.length > 15 ? `${d.data.name.slice(0, 15)}...` : d.data.name)
        .style("font-size", d => {
          // const baseSize = 16;
          // const size = baseSize + d.depth * 2;
          // console.log("size " , size + "  " + d.depth)
          // return `${Math.min(size, 22)}px`;
          return '30px';
        })
      );

      const parent = (
        svg.append("circle")
          .datum(root)
          .attr("r", radius)
          .attr("fill", "#120a3a")
          .attr("pointer-events", "all")
          .on("click", clicked)
      );

      svg.append("text")
        .attr("class", "center-title") // Add class for easier selection
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .style("font-size", "30px")
        .style("fill", "white")
        .text("Ecosystem"); // Initial title for the root


      // Handle zoom on click.
      function clicked(event, p) {
        parent.datum(p.parent || root);

        svg.select("text.center-title")
          .text(p.depth === 0 ? "Ecosystem" : p.data.name.length > 30 ? `${p.data.name.slice(0, 30)}...` : p.data.name) // Update with depth or node name
          .attr("dy", "0.35em")
          .style("font-size", "30px")
          .style("fill", "white")
          .on("mouseover", () => {
            tooltip.style("opacity", 1)
              .html(`${p.data.name}`)
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY + 10) + "px");
          })
          .on("mouseout", () => {
            tooltip.style("opacity", 0);
          });

    
        

        root.each(d => d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        });

        const t = svg.transition().duration(event.altKey ? 7500 : 750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path.transition(t)
          .tween("data", d => {
            const i = d3.interpolate(d.current, d.target);
            return t => d.current = i(t);
          })
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
          .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
          .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none")

          .attrTween("d", d => () => arc(d.current));

        label.filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        }).transition(t)
          .attr("fill-opacity", d => +labelVisible(d.target))
          .attrTween("transform", d => () => labelTransform(d.current));
      }


      function arcVisible(d) {
        return d.y1 <= 2 && d.y0 >= 1 && d.x1 > d.x0;
      }

      function labelVisible(d) {
        return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
      }

      function labelTransform(d) {
        const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
        const y = (d.y0 + d.y1) / 2 * radius;
        return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
      }
      return () => {
        // Clean up on unmount
        d3.select(chartRef.current).selectAll("*").remove();
        tooltip.remove();
      };
    }
  }, [data]);

  return (
    <>
      {(data?.children.length > 0) &&
        <Grid item xs={12} md={12} className="left-fit-contain">
          <Card sx={{ mb: 1 }}>
            <div className="top-heading-dashboard blur-box-cover-area sunburst-blur">

              <h2>Sunburst Chart</h2>
              <div className="total-issues-chart">
                  <div ref={chartRef} style={{ width: "44%", height: "auto", margin: "0px auto" }}></div>
              </div>

            </div>
          </Card>
        </Grid>
      }
    </>
  )
}
export default BluredSunburstChartD3Older;
