import React, { useEffect, useRef, useState } from "react";
import { Grid, Container, Box, CircularProgress } from "@mui/material";
import ArrowRight from "../../assets/arrow-right-profilerisk.svg";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import GetRequest from "../../components/apiConnections/getRequest";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

function RiskProfile({ domainName, vendorName }) {
  const navigate = useNavigate();
  const [compliances, setComplainces] = useState();
  const [loader, setLoader] = useState(false);
  const [inherentRisk, setInherentRisk] = useState(0);
  const [residualRisk, setResidualRisk] = useState(0);
  const [acceptableRisk, setAcceptableRisk] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalMappedQuestions, setTotalMappedQuestions] = useState(0);
  const [ComplinceLoader, setComplinceLoader] = useState(false);
  const [impactsOnVendor, setImpactsOnVendor] = useState({});
  const [impactsOnClient, setImpactsOnClient] = useState({});
  const controller = compliances && Object.entries(compliances).map((control, index) => {
    return {
      name: control[0],
      value: control[1].total,
      series: [
        Math.round((control[1].passed / totalMappedQuestions) * 100),
        Math.round((control[1].failed / totalMappedQuestions) * 100),
        Math.round((control[1].total / totalMappedQuestions) * 100)
      ]
    }
  });


  const [userDetails, setUserDetails] = useState({});
  let roleId = parseInt(window.localStorage.getItem('roleId'));
  function getDetails() {
    let token = localStorage.getItem(roleId == 1 ? "R1" : roleId == 2 ? "R2" : "R3");
    let tokenData = JSON.parse(token)?.accessToken;
    let userData;
    if (tokenData) {
      userData = jwt_decode(tokenData);
      setUserDetails(userData);
    }

  }
  useEffect(() => {
    getDetails();
    getBusinessRiskRatio();
    getCompplianceMapping();
    getResidualInheritanceAcceptableRisk();
    getImpactAnalysis();
  }, []);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "radar",
      toolbar: {
        show: false,
      },
      events: {
        click: function (event, chartContext, config) {
          const labels = chartContext.opts.xaxis.categories; // X-axis labels
          //console.log("labels", labels);
          const chartWidth = chartContext.w.globals.gridWidth; // Get chart width
          //console.log("chartWidth", chartWidth);
          const chartHeight = chartContext.w.globals.gridHeight; // Get chart height
          //console.log("chartHeight", chartHeight);
          const chartRadius = chartHeight / 2; // Approximate radius of the radar chart
          //console.log("chartRadius", chartRadius);

          // Get the position of the mouse click
          const offsetX = event.offsetX;
          const offsetY = event.offsetY;

          // Compute the center of the radar chart
          const centerX = chartWidth / 2;
          const centerY = chartHeight / 2;

          // Calculate the distance of the click from the center of the chart
          const dx = offsetX - centerX;
          const dy = offsetY - centerY;
          const distanceFromCenter = Math.sqrt(dx * dx + dy * dy); // Euclidean distance
          //console.log("distanceFromCenter" , distanceFromCenter)
          // If the click is outside the radar chart (i.e., too far from the center), ignore it
          if (distanceFromCenter > chartRadius + 55) {
            //console.log("Click outside the radar chart");
            return; // Ignore the click
          }

          // Calculate the angle of the click relative to the center of the chart
          const angle = Math.atan2(dy, dx) * (180 / Math.PI); // Angle in degrees

          // Normalize the angle to be within 0 to 360 degrees
          const normalizedAngle = (angle + 360) % 360;
          //console.log("Normalized Angle:", normalizedAngle);

          // Calculate the index of the clicked label based on the angle
          const labelIndex = Math.floor((normalizedAngle / 360) * labels.length);

          // Debugging: Print out the computed index and label
          //console.log("Label Index:", labelIndex);

          // Ensure the labelIndex wraps around correctly
          const adjustedIndex = labelIndex % labels.length;
          if (adjustedIndex == 0) {
            const clickedLabel = labels[2];
            //console.log("clickedLabel", clickedLabel); 
            handleBusinessRatioClick(clickedLabel)
          }
          if (adjustedIndex == 1) {
            const clickedLabel = labels[3];
            //console.log("clickedLabel", clickedLabel); 
            handleBusinessRatioClick(clickedLabel)
          }
          if (adjustedIndex == 2) {
            const clickedLabel = labels[4];
            //console.log("clickedLabel", clickedLabel); 
            handleBusinessRatioClick(clickedLabel)
          }
          if (adjustedIndex == 3) {
            const clickedLabel = labels[0];
            //console.log("clickedLabel", clickedLabel); 
            handleBusinessRatioClick(clickedLabel)
          }
          if (adjustedIndex == 4) {
            const clickedLabel = labels[1];
            //console.log("clickedLabel", clickedLabel); 
            handleBusinessRatioClick(clickedLabel)
          }

        },
      },

    },
    stroke: {
      width: 1,
      colors: [
        "rgba(130, 82, 181, 0.8)",
        "rgba(130, 82, 181, 0.4)",
        "rgba(130, 82, 181, 0)",
      ],
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ["#883CF0", "#684CE8", "#5358E2"], // The second color in the gradient
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.9,
        stops: [0, 80], // Ensures the gradient covers the full range
      },
    },
    dropShadow: {
      enabled: true,
      blur: 15,
      color: "#000",
      opacity: 0.5,
      left: 2,
      top: 2,
    },
    markers: {
      size: 4,
      colors: ["#ADE1FF"],
      strokeColors: "#6E12D0",
      strokeWidth: 2,
    },
    xaxis: {
      categories: [
        "Operational Risk",
        "Compliance Risk",
        "Reputational Risk",
        "Financial Risk",
        "Cybersecurity Risk"
      ],
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Mona-Sans",
          colors: ["#fff", "#fff", "#fff", "#fff", "#fff"],
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: "rgba(255, 255, 255, 0.2)",
          strokeWidth: 1,
          connectorColors: "rgba(255, 255, 255, 0.2)",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      theme: "dark", // This sets the tooltip to a light theme
      background: "#241471",
      style: {
        fontSize: "14px",
        fontFamily: "Mona-Sans",
        color: "#fff",
        backGround: "#241471",
        opacity: 1,
      },
      fillSeriesColor: false, // This ensures that the text color doesn't change with the series color
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Series",
      data: [0, 0, 0, 0, 0],
    },
  ]);

  // Define options for the concentric radial bars
  const options1 = (control) => ({
    chart: {
      type: "radialBar",
      height: 350,
      background: "transparent",
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: "60%",
          background: "transparent", // Make hollow background transparent
          strokeCap: "round",
          rounded: true,
        },
        track: {
          background: "transparent", // Make track background transparent
          strokeWidth: "97%",
          margin: 6,
          strokeCap: "round",
        },
        dataLabels: {
          enabled: true,
          total: {
            show: true,
            label: "Total",
            fontFamily: "Mona-Sans",
            color: "#fff", // Ensure label text is white
            offsetY: -10,
            formatter: (w) => {
              return control.value ? `${control.value} Controls` : `0 Controls`;
            },
          },
          value: {
            fontSize: "18px",
            color: "#fff", // Ensure value text is white
            fontFamily: "Mona-Sans",
          },
          name: {
            fontSize: "20px",
            color: "#fff", // Ensure name text is white
            fontFamily: "Mona-Sans",
          },
        },
        rounded: true,
      },
    },
    labels: ["Passed", "Failed Controls", "Mapping"],
    colors: ["#009420", "#E22222", "#0090FF"], // Customize colors for each radial bar
    legend: {
      show: true,
      position: "bottom",
      offsetY: 10,
      horizontalAlign: "center",
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ["#009420", "#E22222", "#0090FF"], // Set custom marker colors
      },
      labels: {
        colors: "#fff", // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  });

  const getImpactAnalysis = () => {
    setLoader(true);

    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_IMPACT_ANALYSIS}?domain=${domainName}`
      ).then((res) => {
        setImpactsOnVendor(res.data.impactsOnVendorPercentages);
        setImpactsOnClient(res.data.impactsOnClientPercentages);
      });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };

  const getBusinessRiskRatio = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_BUSSINESS_RISK_RATIO}?domain=${domainName}`
      )
        .then((res) => {
          const businessRiskData = res.data.getBusinessRisk;
          delete businessRiskData.totalQuestion;
          let categories = Object.keys(businessRiskData);
          let categoryValue = Object.values(businessRiskData);
          setChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {
              ...prevOptions.xaxis,
              categories: categories, // Set the new categories
            },
          }));

          setChartSeries((prevSeries) =>
            prevSeries.map((series) => ({
              ...series,
              data: categoryValue, // Set the new data
            }))
          );
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };

  const getCompplianceMapping = () => {
    setComplinceLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPLIANCE_MAPPING}?domain=${domainName}`
      )
        .then((res) => {
          setComplainces(res.data.compliances)
          setTotalMappedQuestions(res.data.totalComplianceMappedQuestions);
          setTotalQuestions(res.data.totalQuestions || 0);
          setComplinceLoader(false);
        })
        .catch((err) => {
          setComplinceLoader(false);
          return false;
        });
    } catch (error) {
      setComplinceLoader(false);
      return false;
    }
  };

  const getResidualInheritanceAcceptableRisk = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_INHERENT_RESIDUAL_ACCEPTABLE_RISK}?domain=${domainName}`
      )
        .then((res) => {
          setAcceptableRisk(res.data.acceptableRisk);
          setResidualRisk(res.data.residualRisk);
          setInherentRisk(res.data.inherentRisk);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };

  const getReletionshipTag = (score) => {
    let riskTag;
    if (score && score <= 25) {
      riskTag = "Low";
    } else if (score && score <= 50) {
      riskTag = "Medium";
    } else if (score && score <= 75) {
      riskTag = "High";
    } else if (score && score > 75) {
      riskTag = "Critical";
    }
    return riskTag;
  };

  const handleComplianceClick = (type, name) => {
    const filterData = {
      name: name,
      type: type
    }
    navigate(`/dashboard/vendorDetails/${domainName}`, { state: { filterData, mainTabValue: 4, nestedTabValue: 1 } })
  }

  const handleBusinessRatioClick = (name) => {
    handleComplianceClick("riskCategory", name)
  }

  return (
    <Container maxWidth={false} className="max-w-left">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Risk Profile</h3>
            <p>
              This risk profile combines outside-in and inside-out assessments with relationship
              risk to evaluate issues, vulnerabilities, CVEs, and failed controls. From an industrial
              risk assessment perspective, it identifies impacts and assesses business context
              risks, delivering a comprehensive understanding of the third party's security
              posture.
            </p>
            <h4>Risk Assessment</h4>
            <p>
              Risk Assessment evaluates risk across each assessment lifecycle, starting with
              ,<strong>Inherent Risk</strong>, which represents the initial risk level before any mitigation measures are
              applied. <strong>Residual Risk</strong> reflects the remaining risk after implementing security controls
              and remediation efforts. <strong>Acceptable Risk</strong> is the level of risk an organization is willing to
              tolerate based on its risk appetite.
            </p>
          </div>
          <div className="inherent-risk-full-bacground">
            <div className="top-3-box-risk">
              <h3>Inherent Risk</h3>
              <h4>{Math.round(100 - inherentRisk) || 0}</h4>
              {inherentRisk ? (
                <span
                  className={`tagClass ${getReletionshipTag(
                    Math.round(100 -inherentRisk) || 0
                  )}`}
                >
                  {getReletionshipTag(Math.round(100 - inherentRisk) || 0)}
                </span>
              ) : (
                "-"
              )}
            </div>
            <img src={ArrowRight} alt="arrow" />
            <div className="top-3-box-risk">
              <h3>Residual Risk</h3>
              <h4>{Math.round(100 - residualRisk) || 0}</h4>
              {residualRisk ? (
                <span
                  className={`tagClass ${getReletionshipTag(
                    Math.round(100 - residualRisk || 0)
                  )}`}
                >
                  {getReletionshipTag(Math.round(100 - residualRisk) || 0)}
                </span>
              ) : (
                "-"
              )}
            </div>
            <div className="top-3-box-risk">
              <h3>Acceptable Risk</h3>
              <h4>{acceptableRisk || 0}</h4>
              {acceptableRisk ? (
                <span
                  className={`tagClass ${getReletionshipTag(
                    Math.round(acceptableRisk)
                  )}`}
                >
                  {getReletionshipTag(Math.round(acceptableRisk))}
                </span>
              ) : (
                "-"
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <div className="impact-analysis-section">
            <div className="risk-profile-content">
              <h3>Impact Analysis</h3>
              <p>
                This section evaluates the potential impact of identified risks on vendors and clients,
                considering financial, operational, regulatory, and reputational factors. The analysis provides
                a quantified assessment of risk exposure, enabling organizations to develop targeted
                mitigation strategies and strengthen their overall risk posture.
              </p>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div className="impact-on-vendor-box">
                <h3>Impact on {vendorName || "Vendor"}</h3>
                {impactsOnVendor ? (
                  Object.keys(impactsOnVendor)
                  .filter(key => key && key != null && key.toLowerCase() != "null")
                  .map(
                    (key, value) => (
                      (
                        <div className="impact-analysis-box">
                          <div className="flex-middle-analysis">
                            <p onClick={() => handleComplianceClick("impactOnVendor", key)} style={{ cursor: "pointer" }} >{key}</p>
                            <span>{impactsOnVendor[key]}</span>
                          </div>
                          <div className="chart-background-fill">
                            <Chart
                              options={{
                                chart: {
                                  type: "bar",
                                  height: 30,
                                  sparkline: {
                                    enabled: true, // Hide axes and gridlines
                                  },
                                },
                                plotOptions: {
                                  bar: {
                                    horizontal: true,
                                    barHeight: "30%",
                                    distributed: true,
                                    borderRadius: 5,
                                  },
                                },
                                xaxis: {
                                  categories: [key],
                                  max: 100,
                                },
                                fill: {
                                  colors: ["#DF03CF"],
                                },
                                tooltip: {
                                  enabled: false,
                                },
                              }}
                              series={[{ data: [impactsOnVendor[key]] }]}
                              type="bar"
                              height={30}
                            />
                          </div>
                        </div>
                      )
                    )
                  )
                ) : (

                  (<div className="bussiness-risk" style={{ "marginLeft": "24px" }}>No data found</div>)
                )}
              </div>
              <div className="impact-on-vendor-box">
                {/* <h3>Impact on {userDetails?.firstName} {(userDetails?.firstName && userDetails?.lastName) || "Client"}</h3> */}
                <h3>Impact on {(userDetails?.firstName) || "Client"}</h3>
                {impactsOnClient ? (
                  Object.keys(impactsOnClient)
                  .filter(key => key && key != null && key.toLowerCase() != "null")
                  .map(
                    (key, value) => (
                      (
                        <div className="impact-analysis-box">
                          <div className="flex-middle-analysis">
                            <p onClick={() => handleComplianceClick("impactOnClient", key)} style={{ cursor: "pointer" }} >{key}</p>
                            <span>{impactsOnClient[key]}</span>
                          </div>
                          <div className="chart-background-fill">
                            <Chart
                              options={{
                                chart: {
                                  type: "bar",
                                  height: 30,
                                  sparkline: {
                                    enabled: true, // Hide axes and gridlines
                                  },
                                },
                                plotOptions: {
                                  bar: {
                                    horizontal: true,
                                    barHeight: "30%",
                                    distributed: true,
                                    borderRadius: 5,
                                  },
                                },
                                xaxis: {
                                  categories: [key],
                                  max: 100,
                                },
                                fill: {
                                  colors: ["#DF03CF"],
                                },
                                tooltip: {
                                  enabled: false,
                                },
                              }}
                              series={[{ data: [impactsOnClient[key]] }]}
                              type="bar"
                              height={30}
                            />
                          </div>
                        </div>
                      )
                    )
                  )
                ) : (
                  <div style={{ "marginLeft": "24px" }}>No data found</div>
                )}
              </div>
            </div>
          </div>
        </Grid>

        {/* <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Impact Analysis</h3>
            <p>Figma is a collaborative interface design tool that allows designers to create, prototype, and share their work in real-time. It's widely used for its seamless collaboration features, enabling teams to work together efficiently across different projects. </p>
          </div>
          <div className="impact-analysis-section">
            <img src={ImpactImges} alt="img" />
          </div>
        </Grid> */}
        <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Business Risk Ratio</h3>
            <p>
              This section visually represents key risk areas, including compliance,
              cybersecurity, operational, financial, and reputational risks. The chart provides
              insights into the distribution and balance of risks within the organization's business
              context.
            </p>
          </div>
          <div className="bussiness-risk">
            <h2>Business Risk Ratio</h2>
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="radar"
              height={500}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="risk-profile-content">
            <h3>Compliance Mapping</h3>
            <p>
              Assesses the third party's adherence to industry standards and frameworks,
              highlighting the percentage of passed, failed, and mapped controls to deliver a clear
              and comprehensive view of regulatory alignment.
            </p>
          </div>
          <div className="bussiness-risk">
            <h2>Compliance Mapping</h2>
            <div className="mapping-bussiness-left">
              <div className="flex-compliance-mapping">
                <Grid
                  container
                  spacing={3}
                  style={{ marginBottom: '20px' }}
                >
                  {(controller && controller.length > 0) ?
                    controller.map((control, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={control.name || index}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div className="gdpr-box">
                          <h3 onClick={() => handleComplianceClick("compliance", control.name.toLowerCase())} style={{ cursor: "pointer" }}>{control.name}</h3>
                          <ReactApexChart
                            options={options1(control)}
                            series={control.series}
                            type="radialBar"
                            height={350}
                          />
                        </div>
                      </Grid>
                    )) :
                    (<div className="bussiness-risk" style={{ "marginLeft": "24px" }}>No compliances available </div>)
                  }
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
export default RiskProfile;
